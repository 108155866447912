<template>
    <VueFinalModal
      :modelValue="props.show"
      class="confirm-modal"
      content-class="confirm-modal-content"
      overlay-transition="vfm-fade"
      content-transition="vfm-fade"
      @update:modelValue="emits('update:show', $event)"
    >
      <p class="modal-header">{{ title }}</p>
      <slot />
    </VueFinalModal>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: () => ''
    },
    show: {
        type: Boolean,
        required: true,
        default: () => false
    }
})

const emits = defineEmits(['update:show'])
</script>

<style lang="scss">
.confirm-modal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .confirm-modal-content {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
    border-radius: 0.5rem;
  }
  .confirm-modal-content > * + *{
    margin: 0.5rem 0;
  }
  .confirm-modal-content h1 {
    font-size: 1.375rem;
  }
  .confirm-modal-content button {
    margin: 0.25rem 0 0 auto;
    padding: 0 8px;
    border: 1px solid;
    border-radius: 0.5rem;
  }
  .dark .confirm-modal-content {
    background: #000;
  }
  .vfm__content {
    min-width: 400px;
    min-height: 200px;
    .modal-header {
        margin: 0;
        font-size: 20px;
        font-weight: bold;
        padding: 0px;
    }
  }
</style>
