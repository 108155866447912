export default class BaseTimeSlotsForm {
    timezone = "MSK"
    waterCompanyId = 1
    latitude = 59.826976
    longitude = 30.201890
    clientApp = "site"
    clientType = "fiz"
    paymentType = "nal"
    edo = false
    fromDT = new Date()

    constructor(product) {
        this.timezone = product?.timezone ?? "MSK"
        this.waterCompanyId = product?.waterCompanyId ?? 1
        this.latitude = product?.latitude ?? 59.826976
        this.longitude = product?.longitude ?? 30.201890
        this.clientApp = product?.clientApp ?? "site"
        this.clientType = product?.clientType ?? "fiz"
        this.paymentType = product?.paymentType ?? "nal"
        this.edo = product?.edo ?? false
        this.fromDT = product?.fromDT ?? new Date()
    }
}