<script setup>
import { useUserStore } from "@/store";
import { computed } from "vue";
import { getSideBarLinks } from "@/lib/auxiliary/sidebar.js"
const store = useUserStore();
const userPermissions = computed(() => store.user.permissions);
const userRole = computed(() => store?.user?.role || null);
const departmentId = computed(() => store?.user?.departmentId || null);

const sidebarLinks = computed(() => getSideBarLinks(userPermissions.value, userRole.value, departmentId.value));
</script>

<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="/" class="brand-link xMas-hat__container">
      <img src="@/assets/hat.png" class="xMas-hat" alt="xMas hat" width="29"/> 
      <i :class="{ 'nav-icon': true, 'fa': true, 'fa-laptop': true }" style="color: #c2c7d0; margin-right: 5px"></i>
      <span class="brand-text font-weight-light">CRM3.Hvalwaters</span>
    </a>
    <div class="sidebar">
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

          <li v-for="link in sidebarLinks" :key="link.title" class="nav-item">
            <router-link :to="link.href" class="nav-link">
              <i :class="link.icon"></i>
              <p> {{ link.title }}<i v-if="link.childs.length" class="right fas fa-angle-left"></i></p>
            </router-link>

            <ul class="nav nav-treeview" style="display: none;">
              <li v-for="child in link.childs" :key="child.title" class="nav-item">
                <router-link :to="child.href" class="nav-link">
                  <i :class="child.icon"></i>
                  <p>{{ child.title }}</p>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<style scoped lang="scss">
.xMas-hat__container {
  position: relative;
}
.xMas-hat {
  position: absolute;
  top: -2px;
  left: 25px;
}
</style>