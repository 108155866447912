import api from "@/common/api"

export const getStaffById = async (staffId) => {
    try {
        const {data} = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/staff/${staffId}`)
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}