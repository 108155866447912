<script setup>
import { useUserStore } from "@/store";
import { computed, onBeforeMount, ref } from "vue";
import api from "@/common/api";
import CustomersTable from "./Tables/CustomersTable.vue"
import CustomerFilters from "./CustomerFilters.vue"
import { searchClientsApiURLs } from '@/lib/auxiliary/customerSearch'
import BaseClient from "@/lib/models/BaseClient.js"
import { showErrors } from "@/common/showErrors";
import { useRoute } from "vue-router";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const isLoadingSearchBtn = ref(false)
const route = useRoute()

const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function () {
  });
}

const fetchClientsAsync = async (searchBy = 'phone') => {
  try {
    isLoadingSearchBtn.value = true
    const { data } = await api.get(process.env.VUE_APP_GATE_URL + searchClientsApiURLs[searchBy], { params: clientSearchForm.value })
    clients.value = data.data;
  } catch (error) {
    console.error(error)
  } finally {
    isLoadingSearchBtn.value = false
  }
}

const clientSearchForm = ref(new BaseClient())
const filterErrors = ref([])

const clients = ref([])

const getClientById = async (customerId) => {
  try {
    const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customer/' + customerId)
    clients.value.push(data.data)
  } catch (error) {
    showErrors(error)
  }
}

if (route.query.customerId) {
  getClientById(route.query.customerId)
}

onBeforeMount(() => {
  checkAuth();
});
</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <div class="content-header">
      <div class="container-fluid">
        <h1>Клиенты</h1>
      </div>
      <section class="content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <CustomerFilters 
                v-model="clientSearchForm" 
                :isLoadingSearchBtn="isLoadingSearchBtn"
                :errors="filterErrors" 
                @search="fetchClientsAsync" 
                @new-error="filterErrors = $event" 
              />
              <CustomersTable :clients="clients" />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
