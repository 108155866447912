<script setup>
import BaseOrderFilter from '@/lib/models/BaseOrdersFilters';
import OrdersFiltersComponent from './OrdersFilters.vue';
import { ref } from 'vue'
import { showErrors } from '@/common/showErrors';
import api from '@/common/api';
import moment from 'moment';
import FullOrdersTable from './FullOrdersTable.vue';
import UILoader from '../UI/UILoader.vue';
import { useRoute } from "vue-router";

const ordersFilters = ref(new BaseOrderFilter())
const orders = ref([])
const ordersInfo = ref([])
const isPageLoading = ref(false)
const route = useRoute()

const getFormattedOrderFilters = (orderId) => {
    const orderFiltersCopy = structuredClone(ordersFilters.value)
    orderFiltersCopy.date_from = moment(orderFiltersCopy.date_from ?? new Date()).format('YYYY-MM-DD')
    orderFiltersCopy.date_to = moment(orderFiltersCopy.date_to ?? new Date()).format('YYYY-MM-DD')
    const splitedSearch = (orderFiltersCopy?.search || ' ').split(' ')
    if(splitedSearch.length === 2 && splitedSearch[1] === '') {
        orderFiltersCopy.search = null
    }
    if(orderId) {
        return { order_id: orderId, water_company_id: orderFiltersCopy.water_company_id }
    } 
    return orderFiltersCopy
}
const fetchOrders = async (orderId) => {
    try {
        isPageLoading.value = true
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/orders`, { params: getFormattedOrderFilters(orderId) })
        orders.value = data.data.orders
        ordersInfo.value = data.data.info
    } catch (error) {
        showErrors(error)
    } finally {
        isPageLoading.value = false
    }
}

const updateOrder = async (orderInfo = { orderId: 0, fieldName: '', newValue: '' }) => {
    if (orderInfo.orderId) {
        const changedOrder = orders.value.find((order) => order.id === orderInfo.orderId)
        const reserveValue = changedOrder[orderInfo.fieldName]
        try {
            changedOrder[orderInfo.fieldName] = orderInfo.newValue

            const changedValue = {}
            changedValue[orderInfo.fieldName] = orderInfo.newValue

            await api.patch(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/order/${orderInfo.orderId}`, changedValue)
        } catch (error) {
            changedOrder[orderInfo.fieldName] = reserveValue
            showErrors(error)
        }
    }
}

const showDuplicate = () => {
    orders.value = orders.value.filter((order) => ordersInfo.value.orders_duplicate.ids.includes(order.id))
}

if (route.query.orderId) {
    fetchOrders(route.query.orderId)
}
</script>

<template>
    <div class="content-wrapper">
        <UILoader :loading="isPageLoading"/>
        <!-- header -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Заказы</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#">Раздел</a></li>
                            <li class="breadcrumb-item active">Подраздел</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-make-auto col-lg-12 col-md-12">

                        <!-- Основная информация -->
                        <OrdersFiltersComponent v-model="ordersFilters" @search-orders="fetchOrders" />
                        <!-- алерты -->
                        <div class="form-row">
                            <div class="form-group mb-2 col-md-2 col-xl-2" v-show="ordersInfo.orders_error_count">
                                <div class="card alert-danger mb-1">
                                    <div class="card-body p-2 show-errors" style="cursor: pointer" title="смотреть">
                                        Ошибки в заказах: <b>{{ ordersInfo.orders_error_count }}</b>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2 col-md-2 col-xl-2" v-if="ordersInfo?.orders_duplicate?.count" @click="showDuplicate">
                                <div class="card alert-danger">
                                    <div class="card-body p-2 show-errors" style="cursor: pointer" title="смотреть">
                                        Дубликаты: <b>{{ ordersInfo.orders_duplicate.count }}</b>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-group mb-2 col-md-2 col-xl-2">
                                <div class="card alert-danger">
                                    <div class="card-body p-2 show-errors" style="cursor: pointer" title="смотреть">
                                        Другая ошибка
                                    </div>
                                </div>
                            </div> -->
                        </div>

                        <FullOrdersTable :orders="orders" :ordersInfo="ordersInfo" @update-order="updateOrder" />
                    </div>
                </div>
            </div>
        </section>
        <!-- content off -->
    </div>
</template>