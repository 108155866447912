export default class BaseOrder {
    water_company_id = 1
    order_comment = null
    bottles_return = 0
    need_check = false
    hang_promo = true
    need_pass = false
    leave_at_the_door = false
    window_from_dt = null
    window_to_dt = null
    time_id = null
    delivery_type_id = null
    cart = []
    customer_id = null
    status_id = 1
    address_id = null
    id = null

    updateTimeSlots(timeSlot) {
        console.log(timeSlot, this, 'updateTimeSlots')
        this.window_from_dt = timeSlot.window_from_dt
        this.window_to_dt = timeSlot.window_to_dt
        this.time_id = timeSlot.time_id
        this.delivery_type_id = timeSlot.delivery_type_id
    }

    constructor(order) {
        this.order_comment = order?.order_comment ?? null
        this.bottles_return = order?.bottles_return ?? 0
        this.need_check = order?.need_check ?? false
        this.hang_promo = order?.hang_promo ?? true
        this.need_pass = order?.need_pass ?? false
        this.leave_at_the_door = order?.leave_at_the_door ?? false
        this.cart = order?.cart ?? []
        this.water_company_id = order?.water_company_id ?? 1
        this.window_from_dt = (order?.delivery_date ?? '') + ' ' + (order?.delivery_time?.start ?? '')
        this.window_to_dt = (order?.delivery_date ?? '') + ' ' + (order?.delivery_time?.end ?? '')
        this.time_id = order?.delivery_time?.id ?? null
        this.delivery_type_id = order?.delivery_type_id ?? null
        this.customer_id = order?.customer?.id ?? null
        this.status_id = order?.status_id?.id ?? 1
        this.address_id = order?.address?.id ?? null
        this.id = order?.id ?? null
    }
}