<script setup>
import { defineProps, defineEmits } from "vue"
import UICheckbox from "../UI/UICheckbox.vue";
import UITextarea from "../UI/UITextarea.vue";
import UIInput from "../UI/UIInput.vue";

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => ({})
    }
})
const emits = defineEmits(['update:modelValue'])

const inputHandler = (fieldName, newValue) => {
    const modelValueCopy = structuredClone(props.modelValue)
    modelValueCopy[fieldName] = newValue
    emits('update:modelValue', modelValueCopy)
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-question-circle mr-2"></i>Дополнительно</div>
        <div class="card-body p-2">
            <div class="form-row">
                <UICheckbox label="Нужен чек" class="mb-0 col-md-5 col-xl-5 ml-4"
                    :modelValue="props.modelValue.need_check" @update:modelValue="inputHandler('need_check', $event)" />
                <div class="form-group mb-0 col-md-4 col-xl-4">
                    <UICheckbox label="Повесить промо на
                            бутыль" :modelValue="props.modelValue.hang_promo"
                        @update:modelValue="inputHandler('hang_promo', $event)" />
                    <UICheckbox label="Нужен пропуск" :modelValue="props.modelValue.need_pass"
                        @update:modelValue="inputHandler('need_pass', $event)" />
                    <UICheckbox label="Оставить у двери" :modelValue="props.modelValue.leave_at_the_door"
                        @update:modelValue="inputHandler('leave_at_the_door', $event)" />
                </div>
                <UIInput label="Бутылей вернут" class="mb-0 col-md-2 col-xl-2"
                    :modelValue="props.modelValue.bottles_return"
                    @update:modelValue="inputHandler('bottles_return', +$event)" maxlength="5" :mask="Number" />
            </div>
            <div class="form-row">
                <UITextarea :modelValue="props.modelValue.order_comment"
                    @update:modelValue="inputHandler('order_comment', $event)" class="mb-0 col-md-12 col-xl-12"
                    label="Комментарий к заказу" maxlength="300">
                </UITextarea>
            </div>
        </div>
    </div>
</template>