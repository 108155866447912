import api from "@/common/api";
import { removeEmptyFromObject, getUnMaskedPhone } from '@/lib/utils';

export const getCustomerById = async (customerId) => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customer/${customerId}`)
        const customerBindings = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${customerId}/bindings`)
        data.data.customerBindings = customerBindings.data.data
        return data.data
    } catch (error) {
        console.error(error)
    }
}


export const postCustomer = async (clientForm) => {
    try {
        const { data } = await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customer',
            removeEmptyFromObject({ ...clientForm, phone: getUnMaskedPhone(clientForm.phone) }))
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const patchCustomer = async (clientForm) => {
    try {
        const { data } = await api.patch(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customers/' + clientForm.id,
            removeEmptyFromObject({ ...clientForm, phone: getUnMaskedPhone(clientForm.phone) }))
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}