import api from "@/common/api";
import { removeEmptyFromObject, getUnMaskedPhone } from '@/lib/utils';


export const postAddress = async (addressForm) => {
    try {
        const { data } = await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/addresses',
            removeEmptyFromObject({ ...addressForm, phone: getUnMaskedPhone(addressForm.phone) }))
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const patchAddress = async (addressForm) => {
    try {
        const { data } =await api.patch(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/addresses/' + addressForm.id,
            removeEmptyFromObject({ ...addressForm, phone: getUnMaskedPhone(addressForm.phone) }))
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}