<script setup>
import { defineProps, defineEmits } from 'vue'
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';
import moment from 'moment';
import UISelect from '@/components/UI/UISelect.vue';
const props = defineProps({
    orders: {
        type: Array,
        required: false,
        default: () => []
    },
    filters: {
        type: Object,
        required: true,
        default: () => ({})
    },
    customer: {
        type: Object,
        required: true,
        default: () => ({})
    }
})
const emits = defineEmits(['update:filters', 'search'])
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">
            Статистика заказов
        </div>
        <div class="card-body p-2">
            <div class="form-row">
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    С
                </div>
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    <Datapicker class="form-control form-control-sm" :modelValue="filters.date_from"
                        @update:model-value="emits('update:filters', { ...props.filters, date_from: $event })"
                        :locale="ru" input-format='dd.MM.yyyy' position="left" typeable clearable />
                </div>
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    По
                </div>
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    <Datapicker class="form-control form-control-sm" :modelValue="filters.date_to"
                        @update:model-value="emits('update:filters', { ...props.filters, date_to: $event })"
                        :locale="ru" input-format='dd.MM.yyyy' typeable clearable />
                </div>
                <UISelect class="mb-0 col-md-3 col-xl-3" :modelValue="props.filters.address_id"
                    :options="customer.addresses" sm clearable clearOptionTitle="Адрес не важен"
                    :mapOptions="(option) => ({ title: option.address_source_string, value: option.id })"
                    @update:model-value="emits('update:filters', { ...props.filters, address_id: $event })" />
                <div class="form-group mb-0 col-md-auto col-xl-auto">
                    <button type="submit"
                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                        @click="emits('search')">Найти</button>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-lg-12 col-md-12">
                    <div class="alert alert-info alert-dismissible p-1">
                        Заказов: {{ props.orders.length }}. Бутылей привезено: 0 (4) шт. Бутылей возврашено: 0 шт.
                        Бутылей у клиента: 0
                        шт.<br />
                        Долги: Долгов нет
                    </div>
                </div>
            </div>
            <div class="row pt-2">
                <div class="col-lg-12 col-md-12 pb-4">
                    <table v-if="props.orders.length" class="table table-hover">
                        <thead>
                            <tr>
                                <th>Дата</th>
                                <th>Статус</th>
                                <th>Адрес / заказа</th>
                                <th>Прив</th>
                                <th>Верн</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="order in props.orders" :key="order.id">
                                <td class="col-auto">{{ moment(order.delivery_date).format('DD.MM.YYYY') }}</td>
                                <td class="col-auto">{{ order?.status?.name }}</td>
                                <td class="col-auto">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12">
                                            {{ order?.address?.addressString }}
                                        </div>
                                    </div>
                                    <div class="row font-small-1" v-for="item in order.items" :key="item.id">
                                        <div class="col-lg-7 col-md-7">
                                            {{ item?.product?.name }}
                                        </div>
                                        <div class="col-lg-auto col-md-auto">
                                            {{ item.quantity }} шт / {{ item.currentCost }}&nbsp;Р / {{ item.totalAmount
                                            }}&nbsp;Р
                                        </div>
                                        <div class="col-lg-auto col-md-auto">
                                            {{ item.paymentType.name }}
                                        </div>
                                    </div>
                                </td>
                                <td class="col-auto">{{ order.totalBottles }}</td>
                                <td class="col-auto">{{ order.bottlesReturn }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p v-if="!props.orders.length" class="text-center mt-5 mb-5"><b>Заказов ещё не было</b></p>
                </div>
            </div>
        </div>
    </div>
</template>