<script setup>
import api from '@/common/api';
import { useRoute } from 'vue-router';
import { ref } from 'vue'
import ClientFormComponent from '../Forms/ClientForm.vue';
import UIInput from "@/components/UI/UIInput.vue";
import UICheckbox from '../UI/UICheckbox.vue';
import { showErrors } from '@/common/showErrors';
import { useToast } from 'vue-toastification';
import { removeEmptyFromObject } from '@/lib/utils';
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, or } from '@vuelidate/validators'

const toast = useToast();
const loadingPage = ref(false)

const customerId = useRoute().params.id
const customer = ref({})
const customerForm = ref({})
const clientFormValidationRules = {
    phone: { required: helpers.withMessage('Заполните поле телефон', required) },
    first_name: { required: helpers.withMessage('Заполните имя', required) },
    company: { reuired: helpers.withMessage('Заполните компанию', or(() => customerForm.value.type === 2, required)) }
}
const clientFormValidator = useVuelidate(clientFormValidationRules, customerForm)
const getCustomer = async (id) => {
    try {
        loadingPage.value = true
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customer/${id}`)
        customer.value = structuredClone(data.data)
        customerForm.value = data.data
        customerForm.value.type = data.data.type.id
    } catch (error) {
        console.error(error)
    } finally {
        loadingPage.value = false
    }
}

const updateCustomer = async (newCustomer) => {
    try {
        loadingPage.value = true
        clientFormValidator.value.$validate()
        if (clientFormValidator.value.$errors.length) {
            throw { clientValidateError: 'Проверьте заполнение полей клиента' }
        }
        await api.patch(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${newCustomer.id}`, removeEmptyFromObject(newCustomer))
        toast.success('Клиент обновлён')
    } catch (error) {
        console.error(error)
        showErrors(error)
    } finally {
        loadingPage.value = false
    }
}

getCustomer(customerId)
</script>

<template>
    <div class="content-wrapper">
        <UILoader :loading="loadingPage" />
        <!-- header -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Клиент {{ `${customer.last_name ?? ''} ${customer.first_name}
                            ${customer.patronymic ?? ''}` }} - редактирование</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#">Раздел</a></li>
                            <li class="breadcrumb-item active">Подраздел</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-make-auto col-lg-12 col-md-12">

                        <!-- Клиент -->
                        <ClientFormComponent v-model="customerForm" :validator="clientFormValidator" />

                        <!-- Дополнительно -->
                        <div class="card">
                            <div class="card-header alert-info p-2 pt-1 pb-1"><i
                                    class="fas fa-question-circle mr-2"></i>Дополнительно</div>
                            <div class="card-body p-2">
                                <div class="form-row">
                                    <UIInput v-model="customerForm.contract_number" class="mb-0 col-md-4 col-xl-4" sm
                                        maxlength="11" label="Контракт" />
                                    <UIInput v-model="customerForm.email" class="mb-0 col-md-4 col-xl-4" sm
                                        maxlength="11" label="E-mail" />
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-5 col-xl-5">
                                        <UICheckbox v-model="customerForm.is_need_act" label="Печатать даты акта" />
                                        <UICheckbox v-model="customerForm.is_phone_robot" label="Автоответчик" />
                                        <UICheckbox v-model="customerForm.need_check" label="Всегда отправлять чек" />
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group mb-0 col-md-auto col-xl-auto">
                                        <button type="submit"
                                            class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                                            @click="updateCustomer(customerForm)">Редактировать</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
        <!-- content off -->
    </div>
</template>
