import api from "@/common/api";
import { showErrors } from "@/common/showErrors";
import { removeEmptyFromObject } from '@/lib/utils';
import moment from 'moment'

const getOrderFilterParams = (orderFilters) => {
    const result = structuredClone(orderFilters)
    if (result.date_from)
        result.date_from = moment(orderFilters.date_from).format("YYYY-MM-DD")
    if (result.date_to)
        result.date_to = moment(orderFilters.date_to).format("YYYY-MM-DD")
    return removeEmptyFromObject(result)
}

export const postOrder = async (orderForm) => {
    try {
        const { data } = await await api.post(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/order`, { ...orderForm })
        return data.data
    } catch (error) {
        console.error(error)
        showErrors(error)
        throw error
    }
}

export const patchOrder = async (orderForm) => {
    try {
        const { data } = await await api.patch(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/order/${orderForm.id}`, { ...removeEmptyFromObject(orderForm) })
        return data.data
    } catch (error) {
        console.error(error)
        showErrors(error)
        throw error
    }
}

export const getOrderStatuses = async (params) => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/order-statuses`, { params })
        return data.data
    } catch (error) {
        console.error(error)
        showErrors(error)
    }
}

export const getOrdersByCusomerId = async (customerId, ordersFilters) => {
    try {
        const formattedOrderFilters = getOrderFilterParams(ordersFilters)
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${customerId}/orders`, { params: formattedOrderFilters })
        return data.data
    } catch (error) {
        console.error(error)
        showErrors(error)
    }
}

export const getOrderById = async (orderId) => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/order/${orderId}`)
        return data.data
    } catch (error) {
        console.error(error)
        showErrors(error)
    }
}