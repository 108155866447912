import api from "@/common/api";
import { showErrors } from "@/common/showErrors";


export const getProductList = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/products`)
        return data.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const getPreferredProducts = async (water_company_id) => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/cart-validation/product-preferred', { params: { water_company_id } })
        return data.data
    } catch (error) {
        console.error(error)
        showErrors(error)
    }
}