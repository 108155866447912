<script setup>
import UIButton from "../UI/UIButton.vue";
import api from "@/common/api";
import UIModal from "../UI/UIModal.vue";
import { ref, defineProps, computed, defineEmits, nextTick } from "vue"
import UISelect from "../UI/UISelect.vue";
import BaseProduct from "@/lib/models/BaseProduct";
import UIInput from "../UI/UIInput.vue";
import BaseClient from "@/lib/models/BaseClient";
import { splitNumberByDigit } from "@/lib/utils";
import UICheckbox from "../UI/UICheckbox.vue";


const props = defineProps({
    client: {
        type: Object,
        required: true,
        default: () => new BaseClient()
    },
    cart: {
        type: Array,
        required: true,
        default: () => []
    },
    disabledQty: {
        type: Boolean,
        required: false,
        default: () => false
    },
    products: {
        type: Array,
        required: false,
        default: () => []
    }
})
const emits = defineEmits(['update:cart', 'qty-update', 'fix-price'])


const showAddProductModal = ref(false)
const getProductById = (productId) => {
    return props.products.find((product) => product.id === productId) ?? {}
}


const popularProducts = ref([416, 448, 718, 3980, 870])
const currentProduct = ref(new BaseProduct())
const paymentTypes = ref([])


const addProductToBasket = () => {
    emits('update:cart', [currentProduct.value, ...props.cart])
    showAddProductModal.value = false
    currentProduct.value = new BaseProduct()
    nextTick(() => {
        emits('fix-price', {idx: 0, event: true})
    })
}
const removeProductByIdx = (productIdx) => {
    const cartCopy = structuredClone(props.cart)
    cartCopy.splice(productIdx, 1)
    emits('update:cart', cartCopy)
}
const basketPrice = computed(() => {
    return props.cart.reduce((price, product) => price + product.qty * product.price, 0)
})



const fetchPaymentTypesAsync = async () => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/payment-types', {
            params: {
                water_company_id: props.client.water_company_id
            }
        })
        paymentTypes.value = data.data
    } catch (error) {
        console.error(error)
    }
}

const setProductFields = () => {
    const fullProduct = getProductById(currentProduct.value.product_id)
    currentProduct.value.sell_type_id = fullProduct.sell_types[0].id
    currentProduct.value.price = fullProduct.price
}

const inputHandler = (fieldName, idx, newValue) => {
    const newCart = structuredClone(props.cart)
    newCart[idx][fieldName] = newValue
    emits('update:cart', newCart)
}

const updateQuantity = (idx, event) => {
    inputHandler('qty', idx, event)
    const product = props.cart[idx]
    if (product.isFixedPrice) {
        nextTick(() => { emits('fix-price', {idx: 0, event: product.isFixedPrice}) })
    }
    emits('qty-update')
}

fetchPaymentTypesAsync()
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-shopping-cart mr-2"></i>Корзина<UIButton
                type="submit" class=" btn btn-sm form-control-sm col-2 ml-5" @click="showAddProductModal = true">
                Добавить продукт</UIButton>
        </div>
        <div class="card-body p-2">
            <UIModal title="Добавить продукт" v-model:show="showAddProductModal">
                <section>
                    <h2>Популярные товары</h2>
                    <ul class="order-basket__populer-products-container">
                        <li class="order-basket__populer-product" v-for="product in popularProducts" :key="product"
                            @click="currentProduct.product_id = product; setProductFields()">
                            <img :src="`https://hvalwaters.ru/img/products/${product}`" width="50" />
                            <h3><b>{{ getProductById(product).name }}</b></h3>
                        </li>
                    </ul>
                    <UISelect v-model="currentProduct.product_id" :options="products"
                        @update:model-value="setProductFields()"
                        :mapOptions="(product) => ({ title: product.name, value: product.id })" label="Выберите продукт"
                        sm class="order-basket__products-select" />
                    <div class="order-basket__product-params form-row">
                        <UIInput class="col-xl-3 col-md-3 mb-0 form-group" v-model="currentProduct.qty" :mask="Number"
                            :maxValue="getProductById(currentProduct.id).max_qty" label="Количество" />
                        <UISelect class="col-xl-3 col-md-3 mb-0 form-group" v-model="currentProduct.payment_type_id"
                            label="Тип оплаты" :options="paymentTypes"
                            :mapOptions="(opt) => ({ title: opt.name, value: opt.id })" />
                        <UIInput class="col-xl-3 col-md-3 mb-0 form-group" v-model="currentProduct.price" :mask="Number"
                            label="Фикс. цена" />
                        <UISelect class="col-xl-3 col-md-3 mb-0 form-group" v-model="currentProduct.sell_type_id"
                            label="Тип продажи" :options="getProductById(currentProduct.product_id).sell_types"
                            :mapOptions="(opt) => ({ title: opt.name, value: opt.id })" />
                    </div>
                    <UIButton class="mt-3" @click="addProductToBasket">Добавить продукт</UIButton>
                </section>
            </UIModal>
            <div class="form-row">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="col-auto">Название</th>
                            <th class="col-1">Кол.во</th>
                            <th class="col-1">Месяцев</th>
                            <th class="col-1">Цена за ед.</th>
                            <th class="col-1">Прайс</th>
                            <th class="col-1">Залог</th>
                            <th class="col-1">Оплата</th>
                            <th class="col-1">Продажа</th>
                            <th class="col-auto">Итого</th>
                            <th class="col-auto">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(product, idx) in props.cart" :key="product.product_id">
                            <td class="col-auto">
                                {{ getProductById(product.product_id).name }}<br />
                                <p class="text-muted"><i>{{ getProductById(product.product_id).cart_description }}</i>
                                </p>
                            </td>
                            <td class="col-1">
                                <UIInput :mask="Number" class="form-control-sm" :modelValue="product.qty"
                                    :readonly="props.disabledQty" :maxValue="getProductById(product.product_id).max_qty"
                                    @update:model-value="updateQuantity(idx, $event)" />
                            </td>
                            <td class="col-1">
                                <UIInput v-if="product.sell_type_id !== 1" :mask="Number"
                                    :modelValue="product.rent_months"
                                    @update:modelValue="inputHandler('rent_months', idx, $event)"
                                    class="form-control-sm" maxlength="5" :maxValue="200" />
                            </td>
                            <td class="col-1">
                                <UIInput :mask="Number" class="form-control-sm" :modelValue="product.price"
                                    :readonly="product.isFixedPrice"
                                    @update:modelValue="inputHandler('price', idx, +$event)" :maxValue="100000" />
                            </td>
                            <td class="col-1">
                                <UICheckbox v-if="getProductById(product.product_id).fixed_price"
                                    :modelValue="product.isFixedPrice" @update:modelValue="emits('fix-price', {idx, event: $event})"
                                    label="да" class="mt-2" />
                            </td>
                            <td class="col-1">
                                <UIInput v-if="product.sell_type_id === 3" :mask="Number" class="form-control-sm"
                                    :modelValue="product.rent_price"
                                    @update:modelValue="inputHandler('rent_price', idx, $event)" maxlength="7" />
                            </td>
                            <td class="col-1">
                                <UISelect class="form-control-sm" :options="paymentTypes"
                                    :modelValue="product.payment_type_id"
                                    @update:modelValue="inputHandler('payment_type_id', idx, $event)"
                                    :mapOptions="(opt) => ({ title: opt.name, value: opt.id })" />
                            </td>
                            <td class="col-2">
                                <UISelect class="form-control-sm"
                                    :options="getProductById(product.product_id).sell_types"
                                    :modelValue="product.sell_type_id"
                                    @update:modelValue="inputHandler('sell_type_id', idx, $event)"
                                    :mapOptions="(opt) => ({ title: opt.name, value: opt.id })" />
                            </td>
                            <td class="col-1">
                                {{ splitNumberByDigit(product.price * product.qty) + ' ₽' }}
                            </td>
                            <td class="col-1">
                                <button type="submit" @click="removeProductByIdx(idx)"
                                    class="form-control form-control-sm btn btn-sm btn-danger waves-effect waves-light"
                                    title="Удалить товар"><i class="fas fa-trash"></i></button>
                            </td>
                        </tr>
                        <tr>
                            <td class="col-auto font-small-1">&nbsp;</td>
                            <td class="col-1">&nbsp;</td>
                            <td class="col-1">&nbsp;</td>
                            <td class="col-1">&nbsp;</td>
                            <td class="col-1">&nbsp;</td>
                            <td class="col-1">&nbsp;</td>
                            <td class="col-1">&nbsp;</td>
                            <td class="col-1">&nbsp;</td>
                            <td class="col-auto">
                                <b>{{ splitNumberByDigit(basketPrice) + ' ₽' }}</b>
                            </td>
                            <td class="col-1">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.order-basket__populer-products-container {
    display: flex;
    align-items: center;
    column-gap: 40px;
    list-style: none;
    padding: 0px;
    justify-content: space-between;

    .order-basket__populer-product {
        border: 1px solid #17a2b8aa;
        padding: 10px;
        width: 150px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        -webkit-box-shadow: 4px 4px 8px 2px rgba(23, 162, 184, 0.5);
        -moz-box-shadow: 4px 4px 8px 2px rgba(23, 162, 184, 0.5);
        box-shadow: 4px 4px 8px 2px rgba(23, 162, 184, 0.5);
        transition-duration: .3s;

        cursor: pointer;

        h3 {
            margin: 10px 0px;
            text-align: center;
        }

        &:hover {
            -webkit-box-shadow: 8px 8px 8px 2px rgba(23, 162, 184, 0.5);
            -moz-box-shadow: 8px 8px 8px 2px rgba(23, 162, 184, 0.5);
            box-shadow: 8px 8px 8px 2px rgba(23, 162, 184, 0.5);
        }

        &:active {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
    }
}

.order-basket__products-select {
    width: 100%;
}

.order-basket__product-params {
    display: flex;
    padding-left: 5px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
</style>