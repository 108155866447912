import {defineStore} from "pinia";
import api from "@/common/api";

export const useParamsStore = defineStore("paramsStore", {
    state: () => {
        return {
            cities: [],
            clientTypes: [
                {
                    title: 'юр. лицо',
                    value: 1
                },
                {
                    title: 'физ. лицо',
                    value: 2
                }
            ]
        };
    },
    getters: {
        getUserData: (state) => {
            return state.user;
        }
    },
    actions: {
        async fetchCities () {
            try {
                const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/water-companies')
                this.cities = data.data
              } catch (error) {
                console.error(error)
              }
        }
    },
    persist: true,

});