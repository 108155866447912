<script setup>
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {ref} from "vue";

const departments = ref([])
const getDepartments = async () => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/departments')
    departments.value = data
  } catch (error) {
    showErrors(error)
  }

}
getDepartments();

</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Список отделов</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->

    <section class="content">
      <div class="container-fluid">
        <button class="btn btn-primary" @click="$router.push({name: 'CreateDepartmentPage'})">Добавить</button>

        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="form-row">
                  <table class="table table-striped table-sm">
                    <thead>
                    <tr>
                      <th>Название</th>
                      <th>Руководитель</th>
                      <th>Город</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in departments.data" :key="index">
                      <td>
                        <router-link :to="{ name: 'DepartmentMainSettingsPage', params: { id: item.id }}">
                          {{ item.name }}
                        </router-link>
                      </td>
                      <td>{{ item.owner?.name }}</td>
                      <td>{{ item.water_companies.map(company => company.name).join(', ') }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
