<script setup>
import {computed, onBeforeMount, ref} from "vue";
import api from "@/common/api";
import {} from "@/common/showErrors";
import {useRoute} from "vue-router";
import {showErrors} from "@/common/showErrors";
import StaffForManagerMenu from "@/components/Staff/StaffForManagerMenu.vue";
import StaffRouteSheets from "@/components/Staff/StaffRouteSheets.vue";
import StaffTotalData from "@/components/Staff/StaffTotalData.vue";
import format from "date-fns/format";
import {useToast} from "vue-toastification";
import ru from "date-fns/locale/ru";
import Datapicker from "vue3-datepicker";
import {VueSpinnerClock} from "vue3-spinners";
import {getStaffById} from "@/lib/api/staff";
// import api from "@/common/api";
// import {useRoute} from "vue-router";
// import {useToast} from "vue-toastification";

const toast = useToast();

// const store = useUserStore();
// const isAuthenticated = computed(() => store.user.isAuthenticated);

const route = useRoute();
const staff = ref(null);
const routeSheets = ref(null);
const totalData = ref(null);
const dateFrom = ref(new Date());
const dateTo = ref(new Date());
const loading = ref(false);
const currentBalance = ref(null);
const debt = ref(null);

const getStaff = async () => {
  try {
    staff.value = await getStaffById(route.params.staffId)
  } catch (error) {
    showErrors(error)
  }
}

const canUpdateStatus = computed(() =>
    staff.value?.typeId === 1
);

const getCurrentBalance = async (staffId) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/driver-balance/' + staffId)
    currentBalance.value = data.data
  } catch (error) {
    currentBalance.value = null;
    showErrors(error)
  }
}

const createBalance = async () => {
  try {
    await api.post(`${process.env.VUE_APP_GATE_URL}/salary/api/v1/salary/driver-balance/create/`, {
      staffId: route.params.staffId
    });
    toast.success('Баланс успешно создан');
    await getCurrentBalance(route.params.staffId);
  } catch (error) {
    showErrors(error);
  }
};

const hasBalance = computed(() => currentBalance.value !== null);

const getDebtsForInterval = async () => {
  try {
    const {data} = await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/staff-debt/period'
        , {
          dateFrom: format(dateFrom.value, 'yyyy-MM-dd'),
          dateTo: format(dateTo.value, 'yyyy-MM-dd'),
          staffId: route.params.staffId
        })
    debt.value = data.data
  } catch (error) {
    showErrors(error)
  }
}

const getRouteSheets = async () => {
  loading.value = true
  api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/staff/staff-route-sheets-list/' + route.params.staffId, {
    params: {
      dateFrom: format(dateFrom.value, 'yyyy-MM-dd'),
      dateTo: format(dateTo.value, 'yyyy-MM-dd'),
    }
  }).then(function (response) {
    routeSheets.value = response.data.data.dates ? response.data.data.dates : []
    totalData.value = response.data.data.summary
    if (routeSheets.value.length === 0) {
      toast.info("Маршрутные листы не найдены")
    }
    getDebtsForInterval();
    loading.value = false
  }).catch(function (error) {
    loading.value = false
    showErrors(error)
    return []
  })
}

const handleStatusUpdated = async () => {
    await getRouteSheets();
    await getCurrentBalance(route.params.staffId);
}

onBeforeMount(async () => {
  loading.value = true
  await getCurrentBalance(route.params.staffId);
  await getStaff();
  await getDebtsForInterval();
  loading.value = false
});
</script>

<template>
  <div class="content-wrapper">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-9">
            <H1>Личный кабинет водителя  {{ staff?.firstName }} {{ staff?.lastName }} {{ staff?.patronymic }}</H1>
            <div v-if="!hasBalance" class="alert alert-warning">
              У водителя нет баланса.
              <button @click="createBalance" class="btn btn-primary ml-2">Создать баланс</button>
            </div>
            <h2 v-else>Текущий баланс: {{ currentBalance?.balanceAmount }}</h2>
            <h2>Внебалансовый долг за выбранный период: {{ debt?.totalDebtAmount?.value }}</h2>
          </div>
          <div class="col-sm-3">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
            </ol>
          </div>

        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <StaffForManagerMenu></StaffForManagerMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-3">
            <div class="card">
              <div class="card-header ui-sortable-handle" style="cursor: move;">
                <h3 class="card-title">
                  <i class="fas fa-filter mr-1"></i>Фильтр
                </h3>
              </div>
              <div class="card-body">
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата c</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateFrom"
                        :locale="ru"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label for="wcId" class="col-form-label">Дата по</label>
                    <Datapicker
                        class="form-control"
                        v-model="dateTo"
                        :locale="ru"
                        :lower-limit="dateFrom"
                        input-format='dd.MM.yyyy'
                        typeable
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <button class="btn btn-primary waves-effect waves-light" @click="(getRouteSheets())">
                      Найти
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9 col-md-9">
            <StaffTotalData :totalData="totalData" v-if="totalData"/>
            <StaffRouteSheets
                v-for="(routeSheet, date) in routeSheets"
                :item="routeSheet"
                :key="date"
                :date="date"
                :canUpdateStatus="canUpdateStatus"
                @status-updated="handleStatusUpdated"
            />
          </div>
        </div>
      </div>


    </section>
  </div>
  <section class="content">
    <div class="vuespinner-container" v-if="loading">
      <div class="custom-spinner">
        <VueSpinnerClock size="125" color="darkslategray"/>
        <p class="spinner-text">Получаем данные...</p>
      </div>
    </div>
  </section>
  <!-- content off -->
</template>

<style scoped> .custom-spinner {
  z-index: 1000; /* Установите нужное значение z-index */
  position: fixed; /* Фиксированное позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  transform: translate(-50%, -50%); /* Центрирование по оси */
  background: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  display: flex;
  flex-direction: column; /* Вертикальное расположение элементов */
  justify-content: center;
  align-items: center;
}

.spinner-text {
  margin-top: 20px; /* Отступ сверху */
  font-size: 1.5em; /* Размер шрифта */
  color: darkslategray; /* Цвет текста */
} </style>