<script setup>

import {useUserStore} from "@/store";
import {computed, ref, nextTick} from "vue";
import {showErrors} from "@/common/showErrors";
import api from "@/common/api";
import {useRoute} from "vue-router";
import {useToast} from "vue-toastification";
import router from "@/router";

const route = useRoute();
const toast = useToast();


const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);


const statement = ref(null);
const visibleDeductionAddForm = ref(false);
const visibleDeductionEditForm = ref(false);

const deductionDTO = ref({
  userName: null,
  deductionDescription: null,
  deductionAmount: null,
  statementId: route.params.id,
});

const editDeductionDTO = ref({
  deductionId: null,
  userName: null,
  deductionDescription: null,
  deductionAmount: null,
  statementId: route.params.id,
});
const bottomElement = ref(null);
const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
};
const showDeductionEditForm = (deductionId, userName, deductionAmount, deductionDescription) => {
  visibleDeductionEditForm.value = true
  editDeductionDTO.value.deductionId = deductionId
  editDeductionDTO.value.userName = userName
  editDeductionDTO.value.deductionAmount = deductionAmount
  editDeductionDTO.value.deductionDescription = deductionDescription
  nextTick(() => {
    scrollToBottom()
  })
}


const GetStatement = async (id) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/' + id)
    statement.value = data.data
  } catch (error) {
    showErrors(error.response);
  }
}

const addDeduction = async () => {
  try {
    await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/deduction', deductionDTO.value)
    GetStatement(route.params.id);
    toast.success("Вычет добавлен");
    visibleDeductionAddForm.value = false;
    deductionDTO.value.userName = null;
    deductionDTO.value.deductionDescription = null;
    deductionDTO.value.deductionAmount = null;
  } catch (error) {
    showErrors(error.response);
  }
}

const deleteDeduction = async (id) => {
  try {
    await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/deduction/delete', {
      deductionId: id
    })
    GetStatement(route.params.id);
    toast.success("Вычет добавлен");
    visibleDeductionAddForm.value = false;
  }catch(error) {
    showErrors(error.response);
  }
}

const updateDeduction = async () => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/salary/api/v1/statement/deduction/edit', editDeductionDTO.value)
    toast.success("Вычет обновлён");
    visibleDeductionEditForm.value = false;
    GetStatement(route.params.id);
  }catch(error) {
    showErrors(error.response);
  }
}

const getTotalAmount = (type) => {
  return statement.value?.statementTransactions
      .filter(transaction => type ? transaction.moneyType === type : true)
      .reduce((sum, transaction) => sum + transaction.amount, 0) || 0;
};

const getTotalDeductions = () => {
  return statement.value?.statementDeductions
      .reduce((sum, deduction) => sum + deduction.amount, 0) || 0;
};

GetStatement(route.params.id);

</script>

<template>
  <div class="content-wrapper" v-if="isAuthenticated">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <H1>Редактирование ведомости от {{ statement?.date }} склад {{ statement?.warehouse?.name }}</H1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}">Заказы</router-link>
              </li>
              <li class="breadcrumb-item active">Доставленные</li>
            </ol>
          </div>

        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <div class="card">
          <div class="card-title">
            <h2 class="card-title">Транзакции</h2>
          </div>
          <div class="card-body">
            <div class="form-row">
              <table class="table table-striped table-sm">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Имя водителя</th>
                  <th>Тип денег</th>
                  <th>Сумма</th>
                  <th>Время транзакции</th>
                  <th>Причина</th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in statement?.statementTransactions" :key="index">
                  <td>{{ item.transactionId }}</td>
                  <td> {{ item.staffName }}</td>
                  <td> {{ item.moneyType === 'cash' ? 'Бумажные' : 'Мелочь' }}</td>
                  <td> {{ item.amount }}</td>
                  <td> {{ item.transactionDate }}</td>
                  <td> {{ item.reason }}</td>

                </tr>
                </tbody>
              </table>
              <div class="mt-3 d-flex justify-content-between">
                <p class="p-2 d-flex flex-column align-items-center border"><strong>Общая сумма (Бумажные):</strong> {{ getTotalAmount('cash') }}</p>
                <p class="p-2 d-flex flex-column align-items-center border"><strong>Общая сумма (Мелочь):</strong> {{ getTotalAmount('metal') }}</p>
                <p class="p-2 d-flex flex-column align-items-center border"><strong>Общая сумма (Всего):</strong> {{ getTotalAmount() }}</p>
                <p class="p-2 d-flex flex-column align-items-center border"><strong>Общая сумма вычетов:</strong> {{ getTotalDeductions() }}</p>
                <p class="p-2 d-flex flex-column align-items-center border"><strong>Итоговая сумма:</strong> {{ getTotalAmount() - getTotalDeductions() }}</p>
              </div>
            </div>
            <button type="button" class="btn btn-default float-right"
                    @click="router.go(-1)">Назад
            </button>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="form-row">
              <button class="btn btn-info" @click="visibleDeductionAddForm=true">Добавить вычет</button>
            </div>
          </div>
        </div>

        <div class="card" v-if="visibleDeductionAddForm">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-3"><label for="wcid" class="col-form-label">Сотрудник</label>
                <input type="text" class="form-control" v-model="deductionDTO.userName">
              </div>
              <div class="form-group col-md-3"><label class="col-form-label">Сумма вычета</label>
                <input type="number" class="form-control" v-model="deductionDTO.deductionAmount">
              </div>
              <div class="form-group col-md-6"><label class="col-form-label">Описание</label>
                <input type="text" class="form-control" v-model="deductionDTO.deductionDescription">
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-info" @click="addDeduction()">Сохранить</button>
              <button type="submit" class="btn btn-default float-right" @click="visibleDeductionAddForm=false">Отмена
              </button>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-title">
            <h2 class="card-title">Вычеты</h2>
          </div>
          <div class="card-body">
            <div class="form-row">
              <table class="table table-striped table-sm">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Имя водителя</th>
                  <th>Сумма</th>
                  <th>Комментарий</th>
                  <th>Редактировать</th>
                  <th>Удалить</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in statement?.statementDeductions" :key="index">
                  <td>{{ item.id }}</td>
                  <td> {{ item.userName }}</td>
                  <td> {{ item.amount }}</td>
                  <td> {{ item.description }}</td>
                  <td>
                    <button class="btn-info"
                            @click="showDeductionEditForm(item.id, item.userName, item.amount,item.description );">
                      Редактировать
                    </button>
                  </td>
                  <td>
                    <button class="btn-danger" @click="deleteDeduction(item.id)">Удалить</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card" v-if="visibleDeductionEditForm">
            <div class="card-body">
              <div>
                <h3>Редактирование</h3>
              </div>
              <div class="form-row">
                <div class="form-group col-md-3"><label for="wcid" class="col-form-label">Сотрудник</label>
                  <input type="text" class="form-control" v-model="editDeductionDTO.userName">
                </div>
                <div class="form-group col-md-3"><label class="col-form-label">Сумма вычета</label>
                  <input type="number" class="form-control" v-model="editDeductionDTO.deductionAmount">
                </div>
                <div class="form-group col-md-6"><label class="col-form-label">Описание</label>
                  <input type="text" class="form-control" v-model="editDeductionDTO.deductionDescription">
                </div>
              </div>
              <div class="card-footer">
                <div ref="bottomElement"></div>
                <button type="submit" class="btn btn-info" @click="updateDeduction()">Обновить</button>
                <button type="submit" class="btn btn-default float-right" @click="visibleDeductionEditForm=false">Отмена
                </button>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>
  </div>

  <!-- content off -->
</template>

<style scoped>

</style>