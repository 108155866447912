<script setup>
import Datapicker from "vue3-datepicker";
import ru from 'date-fns/locale/ru';
import { useUserStore } from "@/store";
import { computed, ref, nextTick } from "vue";
import api from "@/common/api";
import ClientForm from "../Forms/ClientForm.vue";
import { useRoute, useRouter } from "vue-router";
import BaseClient from "@/lib/models/BaseClient";
import BaseOrder from "@/lib/models/BaseOrder";
import AddressForm from "../Forms/AddressForm.vue";
import BaseAddress from "@/lib/models/BaseAddress";
import OrderBasket from "./OrderBasket.vue";
import { showErrors } from "@/common/showErrors";
import moment from "moment";
import UISelect from "../UI/UISelect.vue";
import UIButton from "../UI/UIButton.vue";
import OrdersTable from '@/components/Customers/Tables/OrdersTable.vue';
import AdditionalOrderInfoForm from "./AdditionalOrderInfoForm.vue";
import { useToast } from "vue-toastification";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, or } from '@vuelidate/validators'
import debounce from "lodash/debounce";
import { getCustomerById, postCustomer, patchCustomer } from "@/lib/api/customer/index.js";
import { patchAddress, postAddress } from "@/lib/api/address";
import { getPreferredProducts, getProductList } from "@/lib/api/order/products";
import { getOrdersByCusomerId, getOrderStatuses, postOrder, getOrderById, patchOrder } from "@/lib/api/order";
import BaseTimeSlotsForm from "@/lib/models/BaseTimeSlotsForm";

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const router = useRouter()
const route = useRoute()
const toast = useToast()
const checkAuth = () => {
    api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function () {
    });
}

checkAuth();

const clientForm = ref(new BaseClient())
const clientFormValidationRules = {
    phone: { required: helpers.withMessage('Заполните поле телефон', required) },
    contract_number: { required: helpers.withMessage('Заполните номер контракта', required) },
    payment_type: { required: helpers.withMessage('Выберите тип оплаты', required) },
    source: { required: helpers.withMessage('Выберите откуда узнали о нас', required) },
    choice: { required: helpers.withMessage('Заполните почему выбрали нас', required) },
    first_name: { required: helpers.withMessage('Заполните имя', required) },
    company: { reuired: helpers.withMessage('Заполните компанию', or(() => clientForm.value.type === 2, required)) }
}
const clientFormValidator = useVuelidate(clientFormValidationRules, clientForm)
const customerChanged = ref(false)
const customerChangeHandler = (event) => {
    customerChanged.value = true
    timeSlotsForm.value.waterCompanyId = event.water_company_id
}


const customerOrders = ref([])
const addressForm = ref(new BaseAddress())
const addressChanged = ref(false)
const addressFormValidationRules = {
    address_source_string: { required: helpers.withMessage('Заполните полный адрес', required) },
    phone: { required: helpers.withMessage('Заполните поле телефон', required) },
    latitude: { required: helpers.withMessage('Заполните ширину', required) },
    longitude: { required: helpers.withMessage('Заполните долготу', required) }
}
const addressFormValidator = useVuelidate(addressFormValidationRules, addressForm)
const isLoading = ref(false)
const timeSlots = ref([])
const isLoadingTimeSlots = ref(false)
const ordersFilters = ref({ date_from: null, date_to: null, address_id: null, water_company_id: 0 })
const orderStatuses = ref([])
const customerId = ref(null)
customerId.value = route.query.customerId

const orderId = ref(null)
orderId.value = route.query.orderId

const orderForm = ref(new BaseOrder({ cart: [] }))
const orderBasket = ref(null)

const getProductById = (productId) => {
    return products.value.find((product) => product.id === productId) ?? {}
}
const getDefaultCart = async () => {
    const defaultProducts = await getPreferredProducts(clientForm.value.water_company_id)
    orderForm.value.cart = defaultProducts
    for (const orderItemIdx in defaultProducts) {
        fixPriceHandler({ idx: orderItemIdx, event: true })
    }
}
const fixPriceHandler = ({ idx, event }) => {
    const product = orderForm.value.cart[idx]
    if (event) {
        const currentProduct = getProductById(product.product_id)
        if (!currentProduct.fixed_price) {
            return
        }
        let newPrice = 0
        let previosValue = Object.keys(currentProduct.fixed_price)[0]
        for (const price in currentProduct.fixed_price) {
            if (+price > +product.qty) {
                newPrice = currentProduct.fixed_price[previosValue]
                break;
            }
            previosValue = price
        }
        const lastFixedPriceQty = Object.keys(currentProduct.fixed_price).at(-1)
        if (+lastFixedPriceQty < +product.qty) {
            newPrice = currentProduct.fixed_price[lastFixedPriceQty]
        }
        orderForm.value.cart[idx].price = newPrice
    }
    nextTick(() => {
        orderForm.value.cart[idx].isFixedPrice = event
    })
}
const orderFormValidationRules = {
    time_id: { required: helpers.withMessage('Заполните слот для доставки', required) },
}
const orderFormValidator = useVuelidate(orderFormValidationRules, orderForm)
const deliveryTimes = ref([])

const fetchDeliveryTimes = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/delivery-times`, { params: { water_company_id: clientForm.value.water_company_id } })
        deliveryTimes.value = data.data
    } catch (error) {
        console.error(error)
    }
}

const fetchCustomerById = async (customerId) => {
    isLoading.value = true
    const customerData = await getCustomerById(customerId)
    clientForm.value = new BaseClient(customerData)
    orderForm.value.water_company_id = customerData.water_company.id
    orderForm.value.customer_id = customerData.id
    addressForm.value = customerData.addresses.find((customerAddress) => customerAddress.is_primary) || customerData.addresses[0]
    orderForm.value.address_id = addressForm.value.id
    ordersFilters.value.water_company_id = customerData?.water_company?.id

    customerChanged.value = false
    await getCustomerOrders(customerData.id, ordersFilters.value)
    await fetchProductList()
    await getDefaultCart()
    isLoading.value = false
}


const getCustomerOrders = async (customerId, ordersFilters = {}) => {
    customerOrders.value = await getOrdersByCusomerId(customerId, ordersFilters)
}

// В лишнем парсе винить Иванова Даниила и отказавшегося править Лацу Давида, по причине не желания ронять все сервисы хв
const cartToDSSNaming = (oldCart) => {
    return oldCart.map((product) => ({ productId: product.product_id, quantity: product.qty }))
}

// В лишнем парсе винить Иванова Даниила и отказавшегося править Лацу Давида, по причине не желания ронять все сервисы хв
const timeSlotsToClient = computed(() => {
    if (!timeSlots.value.length) {
        return []
    }
    const timeSlotsCopy = structuredClone(timeSlots.value)
    const timeSlotsCopySorted = timeSlotsCopy.sort((slot1, slot2) => slot1 - slot2)
    const firstDate = timeSlotsCopySorted[0].window_from_dt
    const result = [{
        title: new Date(firstDate) === new Date() ? 'Сегодня' : moment(firstDate).format('DD.MM.YYYY'),
        value: 'today',
        disabled: true,
        bold: true
    }]
    let currentDay = new Date(firstDate).getDay()
    for (const slot of timeSlotsCopySorted) {
        if (new Date(slot.window_from_dt).getDay() !== currentDay) {
            currentDay = new Date(slot.window_from_dt).getDay()
            result.push({
                title: moment(slot.window_from_dt).format('DD.MM.YYYY'),
                value: moment(slot.window_from_dt).format('DD.MM.YYYY'),
                disabled: true,
                bold: true
            })
        }
        const deliveryTimeName = deliveryTimes.value.find(time => time.id === slot.time_id)?.name || ''
        result.push({
            title: `с ${moment(slot.window_from_dt).format('HH:mm')} до ${moment(slot.window_to_dt).format('HH:mm')} (${deliveryTimeName})`,
            value: slot
        })
    }
    return result
})

const timeSlotsForm = ref(new BaseTimeSlotsForm())

const fetchTimeSlots = async () => {
    if (isLoadingTimeSlots.value) {
        return
    }
    try {
        isLoadingTimeSlots.value = true
        const { data } = await api.post(`${process.env.VUE_APP_GATE_URL}/dss/api/v1/slots_extended`, {
            ...timeSlotsForm.value,
            cart: cartToDSSNaming(orderForm.value.cart),
            fromDT: timeSlotsForm.value?.fromDT || new Date()
        }
        )
        timeSlots.value = data.data
    } catch (error) {
        showErrors(error)
    } finally {
        isLoadingTimeSlots.value = false
    }
}
const fetchTimeSlotsDebounce = debounce(fetchTimeSlots, 1000)

const changeAddressHandler = (addressId) => {
    if (!addressId) {
        addressForm.value = new BaseAddress({ customer_id: clientForm.value.id });
        orderForm.value.address_id = null
    }
    else {
        addressForm.value = clientForm.value.addresses.find((customerAddress) => +customerAddress.id === +addressId)
    }
}

const formatTimeSlotValue = () => {
    if (!orderForm.value.window_from_dt) {
        return 'Выберите слот'
    }
    const deliveryTimeName = deliveryTimes.value.find(time => time.id === orderForm.value.time_id)?.name || ''
    console.log(moment(orderForm.value.window_from_dt).format('HH:mm'), moment(orderForm.value.window_from_dt), orderForm.value.window_from_dt, 'asdsad')
    return `${moment(orderForm.value.window_from_dt).format('DD.MM.YYYY')} - с ${moment(orderForm.value.window_from_dt).format('HH:mm')} до ${moment(orderForm.value.window_to_dt).format('HH:mm')} (${deliveryTimeName})`
}
const updateSlotsHandler = (event) => {
    orderForm.value.window_from_dt = event.window_from_dt
    orderForm.value.window_to_dt = event.window_to_dt
    orderForm.value.time_id = event.time_id
    orderForm.value.delivery_type_id = event.delivery_type_id
}

const fetchOrderStatuses = async (params) => {
    orderStatuses.value = await getOrderStatuses(params)
}

const createOrder = async () => {
    orderFormValidator.value.$validate()
    if (orderFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей заказа' }
    }
    if (!orderId.value) {
        return await postOrder(orderForm.value)
    } else {
        await patchOrder(orderForm.value)
    }
    return {orderId: orderId.value}
}



const fetchNewClient = async () => {
    clientFormValidator.value.$validate()
    if (clientFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей клиента' }
    }

    let customerData;
    if (customerChanged.value && clientForm.value.id) {
        customerData = await patchCustomer(clientForm.value)
    } else if (!clientForm.value.id) {
        customerData = await postCustomer(clientForm.value)
        addressForm.value.customer_id = customerData.customerId
        orderForm.value.customer_id = customerData.customerId
        clientForm.value.id = customerData.customerId
    }
    customerChanged.value = false
    return customerData
}

const fetchNewAddress = async () => {
    addressFormValidator.value.$validate()
    if (addressFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей адреса' }
    }

    let addressData;
    if (addressChanged.value && addressForm.value.id) {
        addressData = await patchAddress(addressForm.value)
    } else if (!addressForm.value.id) {
        addressData = await postAddress(addressForm.value)
        orderForm.value.address_id = addressData.id
        addressForm.value = new BaseAddress(addressData)
    }
    addressChanged.value = false
    return addressData
}

const submitCreateOrder = async () => {
    try {
        await fetchNewClient()
        await fetchNewAddress()

        const { orderId } = await createOrder()
        router.push({ path: '/orders', query: { orderId } })
        toast.success(orderId.value ? 'Заказ создан!' : 'Заказ отредактирован!')
    } catch (error) {
        showErrors(error)
    }
}

const products = ref([]);
const fetchProductList = async () => {
    products.value = await getProductList()
}

const loadPageWithoutCustomer = async () => {
    await fetchProductList()
    await getDefaultCart()
}

const loadEditPage = async () => {
    orderForm.value = new BaseOrder(await getOrderById(orderId.value))
    clientForm.value.id = orderForm.value.customer_id
    addressForm.value.id = orderForm.value.address_id
    fetchCustomerById(clientForm.value.id)
    fetchOrderStatuses({status_id: orderForm.value.status_id, create: false})
}


if (orderId.value) {
    loadEditPage()
} else {
    if (customerId.value) {
    fetchCustomerById(customerId.value)
    } else {
        loadPageWithoutCustomer()
    }
    fetchOrderStatuses({create: true})
}

fetchDeliveryTimes()
</script>

<template>
    <div class="content-wrapper" v-if="isAuthenticated">
        <UILoader :loading="isLoading" />

        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Добавить заказ</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#">Раздел</a></li>
                            <li class="breadcrumb-item active">Подраздел</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-make-auto col-lg-12 col-md-12">
                    <div v-if="clientForm.customerBindings.some((bind) => bind.payer.amount !== 0)" class="card alert-danger">
                        <div class="card-body p-2">
                            Безналичный долг<br />
                            <span v-for="duty in clientForm.customerBindings.filter(binding => binding.payer.amount !== 0)" :key="duty.id">
                                {{ duty.payer.name }} - сумма: {{ duty.payer.amount }}&nbsp;₽<br />
                            </span>
                            Итого: <b>{{ clientForm.customerBindings.reduce((acc, cur) => {return acc + cur.payer.amount}, 0) }} &nbsp;Р</b>
                        </div>
                    </div>

                    <!-- Справочные данные -->
                    <div class="card">
                        <div class="card-body p-2">
                            <div class="form-row">
                                <div class="form-group mb-0 col-md-auto mb-0">
                                    Бонус за рефералов: <b>1000,00</b>&nbsp;Р
                                </div>
                                <div class="form-group mb-0 col-md-auto mb-0">
                                    |
                                </div>
                                <div class="form-group mb-0 col-md-auto mb-0">
                                    Бонус за воду: <b>2000,00</b>&nbsp;Р
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Основная информация -->
                    <ClientForm v-model="clientForm" isMainInfo :validator="clientFormValidator"
                        @update:modelValue="customerChangeHandler" />

                    <!-- Адрес -->
                    <AddressForm v-model="addressForm" :addresses="clientForm.addresses"
                        v-model:currentAddress="orderForm.address_id" :newAddress="orderForm.address_id === null"
                        @update:modelValue="addressChanged = true" :customerType="clientForm.type"
                        :validator="addressFormValidator" @update:currentAddress="changeAddressHandler" />

                    <!-- Товары и корзина -->
                    <OrderBasket ref="orderBasket" :client="clientForm" v-model:cart="orderForm.cart"
                        @qty-update="fetchTimeSlotsDebounce" :disabledQty="isLoadingTimeSlots" :products="products"
                        @fix-price="fixPriceHandler" />

                    <!-- Слоты -->
                    <div class="card">
                        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-clock mr-2"></i>Время
                            доставки</div>
                        <div class="card-body p-2">
                            <div class="form-row">
                                <Datapicker class="form-control form-control-sm" v-model="timeSlotsForm.fromDT"
                                    @update:model-value="fetchTimeSlots" :locale="ru" input-format='dd.MM.yyyy'
                                    position="left" typeable clearable :lowerLimit="new Date()"
                                    :disabled="isLoadingTimeSlots" />
                                <UISelect :options="timeSlotsToClient" :modelValue="orderForm"
                                    @update:model-value="updateSlotsHandler" :search="false"
                                    class="form-group mb-0 col-md-5 col-xl-5"
                                    :currentInputValueFormatFunction="formatTimeSlotValue"
                                    :errors="orderFormValidator.time_id.$errors" />
                                <div class="form-group mb-0 col-md-2 col-xl-2">
                                    <UIButton type="submit" :isLoading="isLoadingTimeSlots"
                                        :disabled="isLoadingTimeSlots"
                                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                                        @click="fetchTimeSlots">Обновить</UIButton>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Дополнительно -->
                    <AdditionalOrderInfoForm v-model="orderForm" />

                    <!-- Оформление заказа -->
                    <div class="card">
                        <div class="card-body p-2">
                            <div class="form-row">
                                <div class="form-group mb-0 col-md-8 col-xl-8">
                                    <p class="text-muted p-0 m-0"><i>лог, если пришёл с backend</i></p>
                                </div>
                                <UISelect v-model="orderForm.status_id" :options="orderStatuses"
                                    :mapOptions="(option) => ({ title: option.name, value: option.id })"
                                    :search="false" />
                                <div class="form-group mb-0 col-md-2 col-xl-2">
                                    <button type="submit" @click="submitCreateOrder"
                                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light">{{ orderId ? 'Редактировать' : 'Добавить'}}
                                        заказ</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-lg-10 col-md-12">
                    <!-- Статистика -->
                    <OrdersTable :orders="customerOrders" :customer="clientForm" v-model:filters="ordersFilters"
                        @search="getCustomerOrders(clientForm.id, ordersFilters)" />
                </div>
            </div>
        </div>
    </div>
</template>
