import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {useToast} from "vue-toastification";

export const removeEmptyFromObject = (obj) => {
    return Object.fromEntries(
        Object.entries(obj)
          .filter(([key, value]) => key && value !== null)
          .map(([key, value]) => [key, value === Object(value) ? removeEmptyFromObject(value) : value])
      );
}
export const getFormattedPhoneNumber = (phone = '') => {
    let result = parsePhoneNumberFromString(phone, 'RU')?.formatNational() || ''

    if (result.startsWith('8') || result.startsWith('7')) {
        result = '+7' + result.slice(1);
    }
    return result
}

export const splitNumberByDigit = (value) => {
    if (!value) {
      return 0
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u2006')
}

export const addToClipboard = async (value = '', succesText = 'Значение скопировано') => {
  const toast = useToast();
  if(value) {
    const result = await navigator.clipboard.writeText(value)
    toast.success(succesText);
    return result
  }
  return
}

export const getUnMaskedPhone = (phone) => {
  return phone.replace(/\D/g, '')
}