export const searchClientsApiURLs = {
    name: '/crm3core/api/v1/customers/searchByFio',
    phone: '/crm3core/api/v1/customers/searchByPhone',
    company: '/crm3core/api/v1/customers/searchByCompany',
    address: '/crm3core/api/v1/customers/searchByAddress',
    contract_number: '/crm3core/api/v1/customers/searchByContractNumber'
}

export const filterFieldsBySearchBy = {
    name: [
        { title: 'Фамилия', model: 'last_name', minLength: 2 },
        { title: 'Имя', model: 'first_name', minLength: 2 },
        { title: 'Отчество', model: 'patronymic', minLength: 2 }
    ],
    phone: [{ title: 'Телефон', model: 'phone', minLength: 2, mask: '+7 (000) 000-00-00' }],
    company: [{ title: 'Компания', model: 'company', minLength: 2 }],
    address: [{ title: 'Адрес', model: 'street', minLength: 2 }],
    contract_number: [{ title: 'Номер договора', model: 'contract_number', minLength: 2 }]
}

export const searchByOptions = [
    { title: 'ФИО', value: 'name' },
    { title: 'Компания', value: 'company' },
    { title: 'Адрес', value: 'address' },
    { title: 'Телефон', value: 'phone' },
    { title: 'Номер договора', value: 'contract_number' },
]
