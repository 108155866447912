<script setup>
import {defineProps, defineEmits, ref} from "vue";

const props = defineProps({
  data: {
    type: Array,
    required: true
  },
  warehouses: {
    type: Array,
    default: () => []
  },
  sortDirection:{
    type: String,
  }
});

const getWarehouseName = (warehouseId) => {
  if (!props.warehouses || props.warehouses.length === 0) return 'Загрузка...';
  const warehouse = props.warehouses.find(w => w.id === warehouseId);
  return warehouse ? warehouse.short_name : 'Не указан';
};

const sortState = ref({ field: '', order: '' });


const emit = defineEmits(['sort']);
const handleSort = (field) => {
  if (sortState.value.field === field) {
    sortState.value.order = props.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    sortState.value.field = field;
    sortState.value.order = props.sortDirection === 'asc' ? 'desc' : 'asc';
  }
  emit('sort', { field, order: sortState.value.order });
};

const getSortIcon = (field) => {
  if (sortState.value.field !== field) return '▼';
  return sortState.value.order === 'desc' ? '▲' : '▼';
};

</script>

<template>
  <div id="paginate_list" class="row">
    <table class="table table-responsive-md staff-list">
      <thead>
      <tr>
        <th @click="handleSort('lastName')">ФИО <span>{{getSortIcon('lastName')}}</span>
</th>
        <th>ID/old ID</th>
        <th>Телефон</th>
        <th @click="handleSort('typeId')">Тип <span>{{getSortIcon('typeId')}}</span></th>
        <th>ИП</th>
        <th>Код 1С</th>
        <th @click="handleSort('defaultWarehouseId')">Основной склад <span>{{getSortIcon('defaultWarehouseId')}}</span></th>
        <th>ЛК</th>
      </tr>
      </thead>
      <tbody>
      <tr class="1" v-for="(item, index) in props.data" :key="index">
        <td>
          <router-link :to="{ name: 'StaffCard', params: { id: item.id }}">
            {{ item.lastName }} {{ item.firstName }} {{ item.patronymic }}
          </router-link>
        </td>
        <td>{{ item.id }} / {{ item.oldId }}</td>
        <td> {{ item.phone }}</td>
        <td> {{ item.typeId == 1 ? 'Водитель' : 'Экспедитор' }}</td>
        <td>{{ item.hasIP ? 'Да' : 'Нет' }}</td>
        <td>{{ item.code1C }}</td>
        <td>{{ getWarehouseName(item.defaultWarehouseId) }}</td>
        <td>
          <button class="btn btn-info" @click="$router.push({name: 'StaffForManagerSheetsPage', params: {staffId:item.id}})">ЛК Водителя</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
th span {
  margin-left: 5px;
  cursor: pointer;
}
</style>