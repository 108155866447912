<template>
    <div class="ui-input form-group" :class="props.class ? props.class : 'col-md-2 col-xl-2'">
        <label v-if="props.label" :for="UIInput?.id" class="col-form-label" :class="{ 'pb-1': props.sm }">{{ props.label }}</label>
        <input v-if="props.mask" v-uid ref="UIInput" type="text" class="form-control form-control-sm"
        :class="props.errors.length ? 'has-error ' + inputClass : inputClass"
            :value="props.modelValue" v-imask="{
                mask: props.mask,
                lazy: true
            }" :readonly="props.readonly" @input="inputHandler" :maxlength="maxlength">
        <input v-if="!props.mask" v-uid ref="UIInput" type="text" class="form-control form-control-sm" 
            :class="props.errors.length ? 'has-error ' + inputClass : inputClass"
            :value="props.modelValue" :readonly="props.readonly" @input="inputHandler" :maxlength="maxlength">
        <p v-if="errors.length" class="ui-input__error">{{ errors[0].$message }}</p>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';

const props = defineProps({
    modelValue: { type: [String, Number, null], required: true },
    label: { type: String, required: false, default: () => '' },
    minLength: { type: Number, required: false, default: () => 0 },
    required: { type: Boolean, required: false, default: () => false },
    errors: { type: Array, required: false, default: () => [] },
    validate: { type: Boolean, required: false, default: () => false },
    maxlength: { type: [Number, String], required: false, default: () => undefined },
    sm: { type: Boolean, required: false, default: () => true },
    class: { type: [String, null], required: false, default: () => null },
    mask: { type: [String, null, Function], required: false, default: () => null },
    readonly: { type: Boolean, required:  false, default: () => false },
    maxValue: { type: [Number, null], required: false, default: () => null},
    inputClass: { type: String, required: false, default: () => ''}
})

const emits = defineEmits(['update:modelValue', 'new-error'])

const UIInput = ref(null)

const inputHandler = (event) => {
    const newValue = event.target.value
    if (typeof props.maxValue === 'number' && +newValue > props.maxValue) {
        if (+props.modelValue !== +props.maxValue) {
            emits('update:modelValue', props.maxValue)
        }
        UIInput.value.value = props.maxValue
    } else {
        emits('update:modelValue', newValue)
    }
}
</script>

<style scoped>
.ui-input {
    position: relative;
    padding-bottom: 8px;
    .has-error{ 
        border-color: red;
    }
}

.ui-input__error {
    position: absolute;
    margin: 0px;
    bottom: -8px;
    font-size: 12px;
    color: red;
}
.rounded-end-none {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.rounded-start-none {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
</style>