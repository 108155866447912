<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button" title="Меню"><i class="fas fa-bars"></i></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link :to="{ name: 'CustomerSearch' }" class="nav-link">
          Добавить заказ
        </router-link>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link :to="{ name: 'CustomerAdd' }" class="nav-link">
          Добавить клиента
        </router-link>
      </li>
    </ul>

    <ul class="navbar-nav ml-auto">
      <button @click="logout" class="btn btn-danger">Выйти</button>
    </ul>
  </nav>
</template>

<script setup>
import {useUserStore} from "@/store";
import {useRouter} from "vue-router";

const router = useRouter();
const userStore = useUserStore();

const logout = () => {
  localStorage.removeItem('access_token')
  userStore.setAuthentication(false)
  userStore.$reset();
  router.push({name: 'SingIn'})

}
</script>

<style>

</style>

