<script setup>
import { defineProps } from 'vue'
import {VueSpinnerClock} from "vue3-spinners";
const props = defineProps({
    loading: {
        type: Boolean,
        required: true,
        default: () => false
    }
})
</script>

<template>
    <div class="vuespinner-container" v-if="props.loading">
        <div class="custom-spinner">
            <VueSpinnerClock size="125" color="darkslategray" />
            <p class="spinner-text">Получаем данные...</p>
        </div>
    </div>
</template>