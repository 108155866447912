<script setup>
import { defineProps } from 'vue'
import { getFormattedPhoneNumber } from '@/lib/utils'

const props = defineProps({
    addresses: {
        type: Array,
        required: true,
        default: () => []
    }
})
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">Адреса</div>
        <div class="card-body p-0">
            <table class="table table-hover">
                <tbody>
                    <tr v-for="(address, idx) in props.addresses" :key="address.id">
                        <td class="col-auto"><b v-if="idx === 0">{{ address.address_source_string }}</b><span v-else>{{
                                address.address_source_string }}</span></td>
                        <td class="col-2">{{ getFormattedPhoneNumber(address.phone) ?? '' }}</td>
                        <td class="col-2">
                            <div class="form-row">
                                <div class="col-lg-6 col-md-6">
                                    <button type="submit"
                                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                                        title="Редактировать"><i class="fas fa-edit"></i></button>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <button type="submit"
                                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                                        title="Добавить заказ на этот адрес"><i class="fas fa-plus"></i></button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>