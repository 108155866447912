<template>
    <div class="ui-textarea form-group" :class="props.class ? props.class : 'col-md-2 col-xl-2'">
        <label :for="UITextarea?.id" class="col-form-label" :class="{'pb-1': props.sm}">{{ props.label }}</label>
        <textarea v-uid ref="UITextarea" type="text" class="form-control" :class="{'form-control-sm': props.sm}" :value="props.modelValue"
            @input="emits('update:modelValue', $event.target.value)" :maxlength="maxlength"></textarea>
            <p class="ui-textarea__error">{{ getErrors[0] }}</p>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue';

const props = defineProps({
    modelValue: { type: [String, Number, null], required: true },
    label: { type: String, required: false, default: () => '' },
    minLength: { type: Number, required: false, default: () => 0 },
    required: { type: Boolean, required: false, default: () => false },
    errors: { type: Array, required: false, default: () => [] },
    validate: { type: Boolean, required: false, default: () => false },
    maxlength: { type: [Number, String], required: false, default: () => undefined},
    sm: { type: Boolean, required: false, default: () => false },
    class: { type: [String, null], required: false, default: () => null}
})

const emits = defineEmits(['update:modelValue', 'new-error'])

const UITextarea = ref(null)

const getErrors = computed(() => {
    if (!props.validate) {
        return [];
    }

    const newErrors = []
    if (props.required && !props.modelValue) {
        newErrors.push('Поле обязательное')
    }
    if (props.minLength && props.modelValue && String(props.modelValue).length < props.minLength) {
        newErrors.push('Требуется больше символов')
    }

    emits('new-error', newErrors)

    return newErrors
})
</script>

<style lang="scss" scoped>
.ui-textarea {
    position: relative;
    transition-duration: .5s;
    &:focus {
        .form-control {
            height: 100px;
        }
    }
}
.ui-textarea__error {
    position: absolute;
    margin: 0px;
    bottom: -20px;
    font-size: 14px;
    color: red; 
}
</style>