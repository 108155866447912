<script setup>
import { defineProps } from 'vue'
const props = defineProps({
    referrals: {
        type: Object,
        required: true,
        default: () => ({})
    }
})

const getFIO = (person) => {
    let result = ''
    if (person?.last_name)
        result += person.last_name + ' '
    if (person?.first_name)
        result += person.first_name + ' '
    if (person?.patronymic)
        result += person.patronymic + ' '
    return result
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">Реферальная система</div>
        <div class="card-body p-0">
            <table class="table table-hover">
                <tbody>
                    <tr>
                        <td class="col-2 flo">Бонус</td>
                        <td class="col-10">{{ props.referrals.bonus ?? 0 }}&nbsp;Р</td>
                    </tr>
                    <tr>
                        <td class="col-2 flo">Кто его пригласил</td>
                        <td class="col-10">
                            <router-link :to="`/customers/${props.referrals?.invited?.id || ''}`">{{
                                getFIO(props.referrals.invited) }}</router-link>
                        </td>
                    </tr>
                    <tr>
                        <td class="col-2 flo">Кого он пригласил</td>
                        <td class="col-10">
                            <router-link v-for="(person, idx) in props.referrals.called"
                                :key="person.id" :to="`/customers/${person?.id || ''}`" >
                                {{
                                    getFIO(person) + (idx !== props.referrals.called.length - 1 ? ',' : '')
                                }}
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>