<script setup>
import {ref} from "vue";
import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useRoute} from "vue-router";
import DepartmentPageMenu from "@/components/Departments/DepartmentMenu.vue";
import {useToast} from "vue-toastification";

const post = ref({
  id: null,
  name: null,
  departmentId: null,
  permissions: []
})


const usersOnPost = ref([]);
const departmentUsers = ref([]);
const targetUserIdForAdd = ref(null);
const targetUserIdForDelete = ref(null);
const targetPermissionIdForAdd = ref(null);
const targetPermissionIdForDelete = ref(null);
const toast = useToast();
const departmentPermissions = ref([]);

const getDepartmentPermissions = async (id) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/permissions')
    departmentPermissions.value = data.data
  } catch (error) {
    showErrors(error)
  }

}

const getDepartmentUsers = async (id) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/department/' + id + '/users')
    const allUsers = data.data
    departmentUsers.value = allUsers.filter(user =>
        user.perm_service_post_id === null
    );
  } catch (error) {
    showErrors(error)
  }

}

const getPost = async (id) => {
  try {
    const {data} = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + id)
    post.value.id = id
    post.value.name = data.data.name
    post.value.permissions = data.data.permissions
    usersOnPost.value = data.data.users

  } catch (error) {
    showErrors(error.response)
  }
}

const updatePost = async (id) => {
  try {
    await api.put(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + id, post.value)
    toast.success("Успешно обновлено")
  } catch (error) {
    showErrors(error.response)
  }
}

const setUserPost = async (userId, postId) => {
  try {
    await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + postId + '/user/' + userId)
    toast.success('Успешно обновлено')
    getPost(route.params.postId);
    getDepartmentUsers(route.params.id);
  } catch (error) {
    showErrors(error.response)
  }
}

const deleteUserFromPost = async (userId) => {
  try {
    await api.delete(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/user/' + userId + '/post')
    toast.success('Успешно обновлено')
    getPost(route.params.postId);
    getDepartmentUsers(route.params.id);
  } catch (error) {
    showErrors(error.response)
  }
}

const addPermission = async (id) => {
  const newPermission = departmentPermissions.value.find(perm => perm.id === id);
  if (newPermission && !post.value.permissions.some(postPermission=> postPermission.id === newPermission.id)) {
    post.value.permissions.push({id: newPermission.id, name: newPermission.name, description: newPermission.description});
    try {
      await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + post.value.id + '/permissions',
          {permissions: post.value.permissions.map(postPermission => postPermission.id)})
      toast.success('Успешно');
    } catch (error) {
      post.value.permissions = post.value.permissions.filter(postPermission => postPermission.id !== newPermission.id);
      showErrors(error.response)
    }
  } else if (newPermission) {
    toast.info('Разрешение уже есть');
  } else {
    toast.error('Разрешение не найдено');
  }
}

const deletePermission = async (id) => {
  try {
    post.value.permissions = post.value.permissions.filter(postPermission => postPermission.id !== id);
    await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/post/' + post.value.id + '/permissions',
        {permissions: post.value.permissions.map(postPermission => postPermission.id)})
    toast.success('Успешно');
  } catch (error) {
    showErrors(error.response)
  }
}

const route = useRoute();

getPost(route.params.postId);
getDepartmentUsers(route.params.id);
getDepartmentPermissions(route.params.id);

</script>
<template>
  <div class="content-wrapper"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Редактирование должности {{ post.name }}</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item active">Отделы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <DepartmentPageMenu></DepartmentPageMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-6">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Название</label>
                      <input v-model="post.name" type="text" class="form-control">
                    </div>
                  </form>
                  <div class="card-footer">
                    <button type="submit" class="btn btn-info" @click="updatePost(route.params.postId)">Обновить
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Список сотрудников без должности</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" size="25" v-model="targetUserIdForAdd">
                        <option v-for="user in departmentUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info"
                          @click="setUserPost(targetUserIdForAdd,route.params.postId)">Назначить на должность
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="deleteUserFromPost(targetUserIdForDelete)">
                    Снять с должности
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Сотрудники с этой должностью</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" v-model="targetUserIdForDelete" size="25">
                        <option v-for="user in usersOnPost" :key="user.id" :value="user.id">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Список доступных прав в отделе</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" size="25" v-model="targetPermissionIdForAdd">
                        <option v-for="permission in departmentPermissions" :key="permission.id" :value="permission.id">
                          {{ permission.name }} | {{ permission.description }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="addPermission(targetPermissionIdForAdd)">Добавить
                  </button>
                </div>
                <div class="card card-primary card-outline">
                  <button type="submit" class="btn btn-info" @click="deletePermission(targetPermissionIdForDelete)">
                    Убрать
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="card">
              <div class="card-body">
                <div class="card card-primary card-outline">
                  <form>
                    <div class="form-group">
                      <label>Выбранные для должности {{ post.name }}</label>
                    </div>
                    <div class="form-group">
                      <select class="form-control" v-model="targetPermissionIdForDelete" size="25">
                        <option v-for="permission in post.permissions" :key="permission.id" :value="permission.id">
                          {{ permission.name }} | {{ permission.description }}
                        </option>
                      </select>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> <!-- content off --> </div>
</template>
