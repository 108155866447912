<script setup>
import api from '@/common/api';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router'
import CustomerCard from '@/components/Customers/CustomerCard.vue';
import ReferalTable from '@/components/Customers/Tables/ReferalTable.vue';
import AddressTable from '@/components/Customers/Tables/AddressTable.vue';
import OrdersTable from '@/components/Customers/Tables/OrdersTable.vue';
import { removeEmptyFromObject } from '@/lib/utils';
import moment from 'moment';
import { getCustomerById } from '@/lib/api/customer';

const customer = ref({})
const customerOrders = ref([])
const customerReferrals = ref([])
const ordersFilters = ref({ date_from: null, date_to: null, address_id: null, water_company_id: 0 })

const route = useRoute()
const isLoading = ref(false)

const getOrderFilterParams = (orderFilters) => {
    const result = structuredClone(orderFilters)
    if (result.date_from)
        result.date_from = moment(orderFilters.date_from).format("YYYY-MM-DD")
    if (result.date_to)
        result.date_to = moment(orderFilters.date_to).format("YYYY-MM-DD")
    return removeEmptyFromObject(result)
}

const fetchCustomerById = async (customerId) => {
    try {
        isLoading.value = true
        customer.value = await getCustomerById(customerId)
        
        ordersFilters.value.water_company_id = customer.value?.water_company?.id
        getCustomerOrders(customer.value.id, ordersFilters.value)
        getCustomerReferrals(customer.value.id)
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        })
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const getCustomerOrders = async (customerId, ordersFilters = {}) => {
    try {
        isLoading.value = true
        const formattedOrderFilters = getOrderFilterParams(ordersFilters)
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${customerId}/orders`, { params: formattedOrderFilters })
        customerOrders.value = data.data
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const getCustomerReferrals = async (customerId) => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/customers/${customerId}/referrals`)
        customerReferrals.value = data.data
    } catch (error) {
        console.error(error)
    }
}

watch(
    () => route.params,
    () => {
        fetchCustomerById(route.params.id)
    },
    {
        deep: true,
        immediate: true
    }
)
</script>
<template>
    <div class="content-wrapper">
        <UILoader :loading="isLoading" />
        <!-- header -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Клиент {{ `${customer.last_name} ${customer.first_name ?? ''}
                            ${customer.patronymic ?? ''}` }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#">Раздел</a></li>
                            <li class="breadcrumb-item active">Подраздел</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-make-auto col-lg-12 col-md-12">
                        <!-- Информация -->
                        <div class="row">
                            <div class="col-lg-8 col-md-8">
                                <CustomerCard :customer="customer" />
                            </div>
                            <div class="col-lg-4 col-md-4">
                                <div class="card">
                                    <div class="card-header alert-info p-2 pt-1 pb-1">Долги</div>
                                    <div class="card-body p-2">
                                        <b>Безналичный долг</b><br />
                                        {Безналичный долг}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Реферальная система -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <ReferalTable :referrals="customerReferrals" />
                            </div>
                        </div>

                        <!-- Юридические лица | только если клиент - юридическое лицо -->
                        <div class="row" v-if="customer?.type?.id === 1">
                            <div class="col-lg-12 col-md-12">
                                <div class="card">
                                    <div class="card-header alert-info p-2 pt-1 pb-1">Связки</div>
                                    <div class="card-body p-0">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th>Плательщик</th>
                                                    <th>Грузополучатель</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="binding in customer.customerBindings" :key="binding.id">
                                                    <td class="col-6">ИНН {{ binding.payer.inn }}<br />{{ binding.payer.name }}</td>
                                                    <td class="col-6">ИНН {{ binding.consignee.inn }}<br />{{ binding.consignee.name }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="row p-2">
                                            <div class="col-lg-auto col-md-auto">
                                                <button type="submit"
                                                    class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light">Добавить
                                                    новую связку</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Адреса -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <AddressTable :addresses="customer.addresses" />
                            </div>
                        </div>

                        <!-- Статистика заказов -->
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <OrdersTable :orders="customerOrders" :filters="ordersFilters" :customer="customer"
                                    @update:filters="ordersFilters = $event"
                                    @search="getCustomerOrders(customer.id, ordersFilters)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- content off -->
    </div>
</template>