<script setup>
import {defineProps, defineEmits, ref} from 'vue';
import format from "date-fns/format";

const props = defineProps({
  data: {
    type: Array,
    required: true
  }
});
const emits = defineEmits(['emit-id']);
const isActive = ref(0);

const emitId = (id) => {
  emits('emit-id', id)
  isActive.value = id;
}

const routeSheetStatusName = {
  in_work: 'В работе',
  completed: 'Выполнен, не сдан',
  was_passed: 'Выручка сдана'
}

const getRouteSheetStatus = (status) => {
  const name = status?.name || 'default';
  return {
    class: `route_sheet_status__${name}`,
    text: routeSheetStatusName[name] || 'Не установлен'
  };
};

</script>

<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title">
        <i class="fas fa-car mr-1"></i>Заявки ({{ props.data.length }})
      </h3>
    </div>
    <div class="card-body">
      <table class="table table-striped table-sm table-hover">
        <thead>
        <tr>
          <th>#</th>
          <th>Дата</th>
          <th>Сотрудники</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in props.data" :key="index">
          <td >{{ index + 1 }}.</td>
          <td style="width: 25%">{{ format(new Date(item.date), 'dd.MM.yyyy') }}
          <br>
            <span v-for="(interval, index) in item.intervals" :key="interval.id" style="display: block">
              {{ interval.name }}<span v-if="index !== item.intervals.length - 1">,</span>
            </span>
          </td>
          <td>
            <b>{{ item.staffList.driver ? item.staffList.driver.fullName : 'Нет информации' }}</b>
            <div v-for="staff in item.staffList.forwarders" :key="staff.id">
              {{ staff.lastName }} {{ staff.firstName }}
            </div>
            <div class="route_sheet_status__wrapper">
              <b>Маршрутный лист:</b>
              <p :class="['route-sheet-status', getRouteSheetStatus(item.routeSheetStatus).class]">
                {{ getRouteSheetStatus(item.routeSheetStatus).text }}
              </p>
            </div>
          </td>
          <td class="text-right">
            <button type="submit" class="btn" :class="{'btn-default': item.id === isActive, 'btn-primary': item.id !== isActive}" @click="() => emitId(item.id)"><i class="fas fa-arrow-right"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped lang="scss">

.route_sheet_status {

  &__in_work {
    color: red;
  }

  &__completed {
    color: orange;
  }

  &__was_passed {
    color: green;
  }
  &__default {
    color: grey;
  }
}

</style>