<script setup>
import CustomersTable from './Tables/CustomersTable.vue';
import { useUserStore } from "@/store";
import { computed, ref } from 'vue'
import ClientFormComponent from '../Forms/ClientForm.vue';
import AddressFormComponent from '../Forms/AddressForm.vue';
import BaseClient from "@/lib/models/BaseClient.js"
import BaseAddress from "@/lib/models/BaseAddress.js"
import api from "@/common/api";
import { removeEmptyFromObject } from '@/lib/utils';
import { useToast } from "vue-toastification";
import { useVuelidate } from '@vuelidate/core'
import { required, helpers, or } from '@vuelidate/validators'
import { showErrors } from '@/common/showErrors';
import { useRouter } from 'vue-router';

const toast = useToast();
const isLoading = ref(false)

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const router = useRouter();



const clientForm = ref(new BaseClient())
const customerChanged = ref(false)
const clientFormValidationRules = {
    phone: { required: helpers.withMessage('Заполните поле телефон', required) },
    contract_number: { required: helpers.withMessage('Заполните номер контракта', required) },
    payment_type: { required: helpers.withMessage('Выберите тип оплаты', required) },
    source: { required: helpers.withMessage('Выберите откуда узнали о нас', required) },
    choice: { required: helpers.withMessage('Заполните почему выбрали нас', required) },
    first_name: { required: helpers.withMessage('Заполните имя', required) },
    company: { reuired: helpers.withMessage('Заполните компанию', or(() => clientForm.value.type === 2, required)) }
}
const clientFormValidator = useVuelidate(clientFormValidationRules, clientForm)
const fetchNewClient = async () => {
    clientFormValidator.value.$validate()
    if (clientFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей клиента' }
    }
    let data;
    if (customerChanged.value) {
        data = await api.patch(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customers/' + addressForm.value.customer_id,
            removeEmptyFromObject({ ...clientForm.value, phone: getUnMaskedPhone(clientForm.value.phone) }))
    } else {
        data = await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customer',
            removeEmptyFromObject({ ...clientForm.value, phone: getUnMaskedPhone(clientForm.value.phone) }))

        addressForm.value.customer_id = data.data.data.customerId
    }
    customerChanged.value = false
    return data.data
}

const addressForm = ref(new BaseAddress())
const addressFormValidationRules = {
    address_source_string: { required: helpers.withMessage('Заполните полный адрес', required) },
    phone: { required: helpers.withMessage('Заполните поле телефон', required) },
    latitude: { required: helpers.withMessage('Заполните ширину', required) },
    longitude: { required: helpers.withMessage('Заполните долготу', required) }
}
const addressFormValidator = useVuelidate(addressFormValidationRules, addressForm)
const fetchNewAddress = async () => {
    addressFormValidator.value.$validate()
    if (addressFormValidator.value.$errors.length) {
        throw { clientValidateError: 'Проверьте заполнение полей адреса' }
    }
    await api.post(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/addresses',
        removeEmptyFromObject({ ...addressForm.value, phone: getUnMaskedPhone(addressForm.value.phone) }))
}

const addNewClientAsync = async () => {
    try {
        isLoading.value = true
        if (!addressForm.value.customer_id || customerChanged.value) {
             await fetchNewClient()
        }
        await fetchNewAddress()
        toast.success("Клиент создан")
        router.push({path: '/customers/search', query: { customerId: addressForm.value.customer_id}})
        addressForm.value = new BaseAddress()
        clientForm.value = new BaseClient()
    } catch (error) {
        showErrors(error)
        console.error(error)
    } finally {
        isLoading.value = false
    }
}



const clients = ref([])
const getSearchForm = () => {
    const clientFields = ['water_company_id', 'customer_type', 'company', 'company_inn', 'last_name', 'first_name', 'patronymic', 'contract_number']
    const addressFields = ['street', 'building', 'flat']
    const result = {}
    for (const field of clientFields) {
        result[field] = clientForm.value[field]
    }
    result.phone = getUnMaskedPhone(clientForm.value.phone ?? '')
    for (const field of addressFields) {
        result[field] = addressForm.value[field]
    }
    return removeEmptyFromObject(result)
}
const searchClientsAsync = async () => {
    try {
        isLoading.value = true

        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/customers/findMatches', { params: getSearchForm() })
        clients.value = data.data;
    } catch (error) {
        console.error(error)
    } finally {
        isLoading.value = false
    }
}

const getUnMaskedPhone = (phone) => {
    return phone.replace(/\D/g, '')
}
</script>

<template>
    <div class="content-wrapper" v-if="isAuthenticated">
        <UILoader :loading="isLoading" />
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2 ">
                    <div class="col-sm-6">
                        <h1 class="m-0">Добавить нового клиента</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#"><i class="nav-icon fas fa-home"></i></a></li>
                            <li class="breadcrumb-item"><a href="#">Раздел</a></li>
                            <li class="breadcrumb-item active">Подраздел</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <!-- header off -->
        <!-- content on -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-auto col-md-12">
                        <!-- Клиент -->
                        <ClientFormComponent v-model="clientForm" @update:model-value="customerChanged = !!addressForm.customer_id" isMainInfo :validator="clientFormValidator" />

                        <!-- Адрес -->
                        <AddressFormComponent v-model="addressForm" :customerType="clientForm.type"
                            :validator="addressFormValidator" />

                        <CustomersTable showButtons :clients="clients" @search="searchClientsAsync"
                            @add-client="addNewClientAsync" />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
