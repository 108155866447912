export default class BaseAddress {
    address_source_string = ''
    water_company_id = 1
    customer_id = null
    flat = null
    office = null
    code = null
    entrance = null
    floor = null
    korp = null
    latitude = null
    longitude = null
    description = null
    working_hours = null
    lunch_hours = null
    logist_comment = null
    manager_comment = null
    spacing = null
    building = null
    phone = null


    constructor(address) {
        this.address_source_string = address?.address_source_string ?? null
        this.water_company_id = address?.water_company_id ?? 1
        this.customer_id = address?.customer_id ?? null
        this.flat = address?.flat ?? null
        this.office = address?.office ?? null
        this.code = address?.code ?? null
        this.entrance = address?.entrance ?? null
        this.floor = address?.floor ?? null
        this.korp = address?.korp ?? null
        this.latitude = address?.latitude ?? null
        this.longitude = address?.longitude ?? null
        this.description = address?.description ?? null
        this.working_hours = address?.working_hours ?? null
        this.lunch_hours = address?.lunch_hours ?? null
        this.logist_comment = address?.logist_comment ?? null
        this.manager_comment = address?.manager_comment ?? null
        this.spacing = address?.spacing ?? null
        this.building = address?.building ?? null
        this.phone = address?.phone ?? null
    }
} 