vue
<script setup> import {useUserStore} from "@/store";
import {computed, onBeforeMount, ref} from "vue";

import api from "@/common/api";
import {showErrors} from "@/common/showErrors";
import {useRoute} from "vue-router";
import router from "@/router";
import {parse} from "date-fns";
import format from "date-fns/format";
import SalaryMenu from "@/components/Salary/SalaryMenu.vue";
import {useToast} from 'vue-toastification';
const toast = useToast();

const store = useUserStore();
const isAuthenticated = computed(() => store.user.isAuthenticated);
const route = useRoute();
const checkAuth = () => {
  api.get(process.env.VUE_APP_GATE_URL + '/api/check').catch(function (error) {
    showErrors(error.response)
  });
}
const period = ref({})

const getPeriod = async (id) => {
  await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/salary/period/?id=' + id).then(function (response) {
    period.value = response.data
    generateDateRange(period)
  }).catch(function (error) {
    showErrors(error)
    router.go(-1)
    return []
  })
}

const getDayOfWeek = (dateString) => {
  const date = new Date(dateString);
  const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
  return days[date.getDay()];
};
const getStatus = (id) => {
  switch (id) {
    case 1:
      return "Открыт"
    case 2:
      return "Закрыт"
    case 3:
      return "Обрабатывается"
    case 4:
      return "Ошибка"
  }
}
const sortedEmployeeData = computed(() => {
  return period.value.data.employeeTotalDataArray.data.slice().sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
});

const dateRange = ref([]);

const generateDateRange = (period) => {
  const startDate = new Date(period.value.data.salaryPeriod.dateFrom);
  const endDate = new Date(period.value.data.salaryPeriod.dateTo);
  const dateArray = [];

  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    dateArray.push(new Date(d).toISOString().split('T')[0]);
  }

  dateRange.value = dateArray;
};

const formatDate = (dateString) => {
  const date = parse(dateString, 'yyyy-MM-dd', new Date());
  return format(date, 'dd.MM.yy');
};

const getTotalForDate = (employeeTotalDataDays, date) => {
  const dayData = employeeTotalDataDays.find(day => day.date === date);
  return dayData ? dayData.total : 0;
};

const additionalExpenses = ref(null);
const getSalaryPeriodAdditionalExpenses = async () => {
  try {
    const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/salary/api/v1/salary/period/${route.params.id}/expenses`)
    additionalExpenses.value = data.data
  } catch (error) {
    showErrors(error)
  }
}

const visibleExpensesAddForm = ref(false);
const additionalExpensesDTO = ref({
  salaryPeriodId:route.params.id,
  expensesName:null,
  expensesAmount:null,
});
const resetExpensesForm = () => {
  additionalExpensesDTO.value = {
    salaryPeriodId: route.params.id,
    expensesName: null,
    expensesAmount: null,
  }
}
const createSalaryPeriodAdditionalExpenses = async () => {
  try {
    const { data } = await api.post(`${process.env.VUE_APP_GATE_URL}/salary/api/v1/salary/period/expenses/`, additionalExpensesDTO.value);
    toast.success(data.message);
    await getSalaryPeriodAdditionalExpenses();
    resetExpensesForm();
    visibleExpensesAddForm.value = false;
  } catch (error) {
    showErrors(error)
  }
}

const updateSalaryPeriodAdditionalExpenses = async (expenses) => {
  try {
    const { data } = await api.put(`${process.env.VUE_APP_GATE_URL}/salary/api/v1/salary/period/expenses/`, expenses);
    toast.success(data.message);
    await getSalaryPeriodAdditionalExpenses();
    visibleExpensesAddForm.value = false;
  } catch (error) {
    showErrors(error)
  }
}

const deleteSalaryPeriodAdditionalExpenses = async (id) => {
  try {
    const { data } = await api.post(`${process.env.VUE_APP_GATE_URL}/salary/api/v1/salary/period/expenses/delete`, {
      id: id
    });
    toast.success(data.message);
    await getSalaryPeriodAdditionalExpenses();
    visibleExpensesAddForm.value = false;
  } catch (error) {
    showErrors(error)
  }
}

onBeforeMount(() => {
  checkAuth();
  getPeriod(route.params.id);
  getSalaryPeriodAdditionalExpenses();
});
</script>
<template>
  <div class="content-wrapper" v-if="isAuthenticated"> <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6"><h1 class="m-0">Период</h1></div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
              <li class="breadcrumb-item">
                <router-link :to="{name: 'DriverSalaryPage'}">Заработная плата</router-link>
              </li>
              <li class="breadcrumb-item active">Водители и экспедиторы</li>
            </ol>
          </div>
        </div>
      </div>
    </div> <!-- header off --> <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <SalaryMenu></SalaryMenu>
      </div>
    </section>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="period.data">
              <div class="card-header"><h3 class="card-title">{{ period.data.salaryPeriod.title }} статус:
                {{ getStatus(period.data.salaryPeriod.statusId) }}</h3></div>
              <div class="card-body">
                <div class="form-row">
                  <table class="table table-striped table-bordered table-sm" >
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>ФИО</th>
                      <th v-for="(date, index) in dateRange" :key="index">{{ formatDate(date) }} {{getDayOfWeek(date)}}</th>
                      <th>Оклад</th>
                      <th>Доплаты</th>
                      <th>Долг</th>
                      <th>Итого</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in sortedEmployeeData" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        <router-link :to="{ name: 'DriverSalaryDetailPage', params: { id: item.salaryPeriodEmployeeId }}" v-if="item.staffType=='Водитель'">
                          {{ item.name }} ({{ item.staffType.substring(0, 3) }}.)
                        </router-link>
                        <router-link :to="{ name: 'ForwarderSalaryDetailPage', params: { id: item.salaryPeriodEmployeeId }}" v-if="item.staffType=='Экспедитор'">
                          {{ item.name }} ({{ item.staffType.substring(0, 3) }}.)
                        </router-link>
                      </td>
                      <td v-for="(date, indexData) in dateRange" :key="indexData">
                        {{ getTotalForDate(item.employeeTotalDataDays, date) }}&nbsp;p.
                      </td>
                      <td>{{ item.salaryPeriodSummary.salary }}&nbsp;p.</td>
                      <td>{{ item.salaryPeriodSummary.ad + item.salaryPeriodSummary.additionalPayment}}&nbsp;p.</td>
                      <td>{{ item.salaryPeriodSummary.duty }}&nbsp;p.</td>
                      <td>{{ item.salaryPeriodSummary.total }}&nbsp;p.</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="form-row">
            <button class="btn btn-info" @click="visibleExpensesAddForm=true">Добавить расход</button>
          </div>
        </div>
      </div>
      <div class="card" v-if="visibleExpensesAddForm">
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-6">
              <label class="col-form-label">Название расхода</label>
              <input type="text" class="form-control" v-model="additionalExpensesDTO.expensesName">
            </div>
            <div class="form-group col-md-3">
              <label class="col-form-label">Сумма расхода</label>
              <input type="number" class="form-control" v-model="additionalExpensesDTO.expensesAmount">
            </div>
          </div>
          <div class="card-footer">
            <button type="submit" class="btn btn-info" @click="createSalaryPeriodAdditionalExpenses()">Сохранить</button>
            <button type="submit" class="btn btn-default float-right" @click="visibleExpensesAddForm=false">Отмена
            </button>
          </div>
        </div>
      </div>

      <div class="form-group col-md-12" v-if="additionalExpenses?.length !==0">
        <h1>Дополнительные расходы</h1>
        <div class="form-row">
          <table class="table table-striped table-sm">
            <thead>
            <tr>
              <th class="expenses__name">Название расхода</th>
              <th class="expenses__amount">Сумма расхода</th>
              <th class="expenses__edit">Изменить</th>
              <th class="expenses__delete">Удалить</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(expenses, index) in additionalExpenses" :key="index">
              <td class="expenses__name">
                <input type="text" class="form-control" v-model="expenses.expensesName">
              </td>
              <td class="expenses__amount">
                <input type="number" class="form-control" v-model="expenses.expensesAmount">
              </td>
              <td class="expenses__edit">
                <btn class="btn btn-info" @click="updateSalaryPeriodAdditionalExpenses(expenses)">Изменить</btn>
              </td>
              <td class="expenses__delete">
                <btn class="btn btn-danger" @click="deleteSalaryPeriodAdditionalExpenses(expenses?.id)">Удалить</btn>
              </td>
            </tr>
            </tbody>
          </table>
          <button type="button" class="btn btn-default"
                  @click="router.go(-1)">Назад
          </button>
        </div>
      </div>


    </section> <!-- content off --> </div>
</template>
<style scoped lang="scss">
.custom-spinner {
  z-index: 1000; /* Установите нужное значение z-index */
  position: fixed; /* Фиксированное позиционирование */
  top: 50%; /* Центрирование по вертикали */
  left: 50%; /* Центрирование по горизонтали */
  transform: translate(-50%, -50%); /* Центрирование по оси */
  background: rgba(255, 255, 255, 0.8); /* Полупрозрачный фон */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  display: flex;
  flex-direction: column; /* Вертикальное расположение элементов */
  justify-content: center;
  align-items: center;
}
.table{
  font-size: 14px;
}
th {
  position: sticky;
  top: 0;
  background-color: #fff; /* Чтобы заголовки были четко видны против контента */
}

.expenses {
  &__name{
    width: 50%;
  }
  &__amount{
    width: 30%;
  }
  &__edit{
    width: 10%;
  }
  &__delete{
    width: 10%;
  }
}
</style>