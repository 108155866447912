<template>
    <div class="form-group position-relative ui-select" :class="props.class ?? 'col-md-2 col-xl-2'"
        ref="UISelectElement" v-if="newType" @click="openSelect">
        <label v-if="props.label" :for="UISelect?.id" class="col-form-label" :class="{ 'pb-1': props.sm }">{{
            props.label
        }}</label>
        <div class="position-relative">
            <input v-uid ref="UISelect" type="text" class="form-control form-control-sm ui-select__input"
                :class="errors.length ? 'has-error ' + inputClass : inputClass" :value="currentInputValueFormatFunction(currentInputValue)"
                @input="queryFilter = $event.target.value" :readonly="props.readonly || !isOpen || !searchAccess"
                :maxlength="maxlength">
            <i class="right fas fa-angle-down ui-select__icon-down" :class="{ 'ui-select__icon-down--reversed': isOpen }" />
        </div>
        <Transition>
            <ul v-show="isOpen" class="ui-select__options-containter" :style="`width: ${width}px`">
                <template v-if="filteredOptions.length">
                    <li v-for="option in filteredOptions" :key="option.value" class="ui-select__option"
                        :class="{ 'ui-select__option--chossen': isOptionChosen(option.id) && !option.disabled, 'ui-select__option--disabled': option.disabled, 'ui-select__option--bold': option.bold }"
                        @click.stop="inputHandler(option)">{{ option.title }}
                    </li>
                </template>
                <template v-else>
                    <li class="ui-select__option--disabled">Результатов не найдено</li>
                </template>
            </ul>
        </Transition>
        <p v-if="errors.length" class="ui-select__error">{{ errors[0].$message }}</p>
    </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref } from 'vue';
import { onClickOutside, useElementSize } from '@vueuse/core'
import isEqual from "lodash/isEqual"
const props = defineProps({
    modelValue: { type: [String, Number, null], required: true },
    label: { type: String, required: false, default: () => '' },
    options: { type: Array, required: false, default: () => [] },
    mapOptions: { type: Function, required: false, default: (option) => option },
    sm: { type: Boolean, required: false, default: () => true },
    newType: { type: Boolean, required: false, default: () => true },
    maxlength: { type: [Number, String], required: false, default: () => undefined },
    search: { type: Boolean, required: false, default: () => null },
    class: { type: [String, null], required: false, default: () => null },
    multiple: { type: Boolean, required: false, default: () => false },
    currentInputValueFormatFunction: { type: Function, required: false, default: (title) => title },
    clearable: {type: Boolean, required: false, default: () => false},
    inputClass: { type: String, required: false, default: () => ''},
    errors: { type: Array, required: false, default: () => [] },
    clearOptionTitle: { type: String, required: false, default: () => 'Все'}
})
const emits = defineEmits(['update:modelValue'])

const UISelect = ref(null)
const UISelectElement = ref(null)
onClickOutside(UISelectElement, () => isOpen.value = false)

const queryFilter = ref('')
const isOpen = ref(false)
const openSelect = () => {
    queryFilter.value = ''
    isOpen.value = !isOpen.value
}

const mappedOptions = computed(() => {
    const result = props.options.map((option) => props.mapOptions(option))
    if (props.clearable) {
        result.unshift({
            title: props.clearOptionTitle,
            value: null
        })
    }
    return result
})
const filteredOptions = computed(() => {
    const result = mappedOptions.value.filter((option) => option.title.toLowerCase().includes(queryFilter.value.toLowerCase()) || option.title === props.clearOptionTitle)
    return result
})
const choosenOption = computed(() => {
    const compareValues = (value1, value2) => {
        if (typeof value1 === 'object') {
            return isEqual(value1, value2)
        }
        return value1 === value2
    }
    return mappedOptions.value.find((option) => compareValues(option.value, props.modelValue)) || { title: '' }
})
const isOptionChosen = (optionId) => {
    if (!props.multiple) {
        return choosenOption.value.id === optionId
    } else {
        return false
    }
}
const searchAccess = computed(() => {
    if (props.search !== null) {
        return props.search
    }
    return props.options.length > 10
})
const currentInputValue = computed(() => {
    if (!searchAccess.value) {
        return choosenOption.value.title
    } else {
        return isOpen.value ? queryFilter.value : choosenOption.value.title
    }
})

const inputHandler = (event) => {
    if (event.disabled) {
        return
    }
    queryFilter.value = event.title
    emits('update:modelValue', event.value)
    isOpen.value = false
}

const { width } = useElementSize(UISelectElement)
</script>

<style lang="scss" scoped>
.ui-select__input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-overflow: ellipsis;
    &.form-control[readonly] {
        background-color: #fff;
    }
    &.has-error{ 
        border-color: red;
    }
}
.ui-select__error {
    position: absolute;
    margin: 0px;
    bottom: -8px;
    font-size: 12px;
    color: red;
}
.ui-select__icon-down {
    position: absolute;
    bottom: 50%;
    right: 6px;
    transform: translateY(50%);
    cursor: pointer;
    transition-duration: .3s;

    &.ui-select__icon-down--reversed {
        transform: translateY(50%) rotate(180deg);
    }
}

.ui-select__options-containter {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 4px 0px;
    border: 1px solid #17a2b8;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0px;
    position: absolute;
    bottom: 8px;
    transform: translateY(100%);
    // width: calc(100% - 10px);
    background-color: white;
    z-index: 12;
    max-height: 300px;
    overflow-y: auto;
    transition-duration: .3s;

    .ui-select__option {
        padding: 4px 16px;
        font-size: 14px;
        cursor: pointer;
    }

    .ui-select__option--chossen {}

    .ui-select__option:hover {
        background-color: #17a2b8;
        color: #fff;
    }

    .ui-select__option--bold {
        font-weight: 900;
    }

    .ui-select__option--disabled:hover {
        background-color: #fff;
        color: #000;
        cursor: default;
    }

    .ui-select__option--disabled {
        padding: 4px 16px;
        font-size: 14px;
    }
}

.ui-select {
    height: min-content;
    padding: 0px 5px;
    padding-bottom: 8px;
}

.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
.rounded-end-none {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.rounded-start-none {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
</style>