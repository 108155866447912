<script setup>
import NavBar from "@/components/Common/NavBar.vue";
import SideBar from "@/components/Common/SideBar.vue";
import FooterBar from "@/components/Common/FooterBar.vue";
import { ModalsContainer } from 'vue-final-modal'

import {useUserStore} from "@/store";
import {computed, onMounted} from "vue";
import router from "@/router";
import { useParamsStore } from "@/store/params";

const userStore = useUserStore();
const isAuth = computed(() => userStore.user.isAuthenticated);

const showMenu = computed(() => {
  if (isAuth.value && (router.currentRoute.value.name !== 'SingIn' || router.currentRoute.value.name !== 'StaffSingIn')) {
    return true
  }
  return false
});

onMounted (() => {
  useParamsStore().fetchCities()
})
</script>

<template>
  <div class="wrapper">
    <NavBar v-show=showMenu />
    <SideBar v-show="showMenu" />
    <router-view></router-view>
    <FooterBar v-show=showMenu />
    <ModalsContainer />
  </div>
</template>

<style lang="scss">
@import './css/style.scss';

html, body {
  margin: 0;
  padding: 0;
}

.wrapper {
  width: 100%;
  height: auto !important;
  position: absolute !important;
}
.input-group {
  .v3dp__datepicker {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0;
      height: calc(1.8125rem + 2px);
      font-size: .875rem;
      line-height: 1.5;
      border-radius: .2rem;
      .v3dp__input_wrapper {
          position: relative;
      }

      .v3dp__clearable {
          position: absolute;
          right: 10px;
          left: unset;
          top: 50%;
          transform: translateY(-50%);
      }
  }
}
</style>
