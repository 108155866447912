<script setup>
import UIInput from "@/components/UI/UIInput.vue";
import UISelect from "@/components/UI/UISelect.vue";
import UITextarea from "@/components/UI/UITextarea.vue";
import { defineProps, defineEmits } from "vue"
import AddressAutocomplete from "@/components/UI/AddressAutocomplete.vue";

const props = defineProps({
    modelValue: { type: Object, required: true },
    customerType: { type: Number, required: false, default: () => 0 },
    addresses: { type: Array, required: false, default: () => [] },
    currentAddress: { type: [Number, String], required: false, default: () => 0 },
    validator: { type: Object, required: false, default: () => ({}) },
    newAddress: { type: Boolean, required: false, default: () => true }
})

const emits = defineEmits(['update:modelValue', 'new-error', 'update:currentAddress'])

const updateHandler = (field, newValue) => {
    const newModelValue = { ...props.modelValue }
    if (typeof field === 'string') {
        newModelValue[field] = newValue
    } else {
        let currentStack = newModelValue
        for (const fieldPart of field) {
            if (fieldPart !== field.at(-1)) {
                currentStack = currentStack[fieldPart]
            }
            else {
                currentStack[fieldPart] = newValue
            }
        }
    }
    emits('update:modelValue', newModelValue)
}

const updateAddressHandler = (suggest) => {
    const newModelValue = { ...props.modelValue }

    newModelValue.address_source_string = (suggest.desc ? (suggest.desc + ', ') : '') + suggest.name
    newModelValue.latitude = suggest.lat || null
    newModelValue.longitude = suggest.lon || null

    emits('update:modelValue', newModelValue)
}

const spacingVariants = [
    {
        value: '',
        title: 'Неважно'
    },
    {
        value: true,
        title: 'Да'
    },
    {
        value: false,
        title: 'Нет'
    }
]
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2"><i class="fas fa-map mr-2"></i>Адрес</div>
        <div class="card-body p-2">
            <div class="form-row">
                <UISelect v-if="addresses.length" class=" mb-0 col-md-12 col-xl-12" sm label="" clearOptionTitle="Новый адрес"
                    :modelValue="props.currentAddress" @update:modelValue="emits('update:currentAddress', $event)"
                    :options="addresses" clearable
                    :mapOptions="(opt) => ({ title: opt.address_source_string, value: opt.id })" />
            </div>
            <div class="form-row">
                <AddressAutocomplete class="form-group mb-0 col-md-10 col-xl-10" label="Адрес" sm
                    :readonly="!newAddress" :modelValue="modelValue.address_source_string"
                    @update:modelValue="updateAddressHandler($event)" :errors="validator.address_source_string?.$errors || []" />
                <UIInput class="mb-0 col-md-1 col-xl-1" sm label="Координаты" :modelValue="props.modelValue.latitude"
                    maxlength="20" :readonly="!newAddress" @update:modelValue="updateHandler(['latitude'], $event)"
                    :errors="validator.latitude?.$errors || []" />
                <UIInput class="mb-0 col-md-1 col-xl-1" :readonly="!newAddress" sm label="&nbsp;"
                    :modelValue="props.modelValue.longitude" @update:modelValue="updateHandler(['longitude'], $event)"
                    maxlength="20" :errors="validator.longitude?.$errors || []" />
            </div>
            <div class="form-row">
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Дом" readonly
                    :modelValue="props.modelValue.building" @update:modelValue="updateHandler('building', $event)"
                    maxlength="10" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Корпус" readonly
                    :modelValue="props.modelValue.korp" @update:modelValue="updateHandler('korp', $event)"
                    maxlength="10" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Этаж" :modelValue="props.modelValue.floor"
                    @update:modelValue="updateHandler('floor', $event)" maxlength="10" />
                <UIInput v-if="customerType === 2" class="mb-0 col-md-2 col-xl-2" sm label="Квартира"
                    :modelValue="props.modelValue.flat" @update:modelValue="updateHandler('flat', $event)"
                    maxlength="10" />
                <UIInput v-if="customerType === 1" class="mb-0 col-md-2 col-xl-2" sm label="Офис"
                    :modelValue="props.modelValue.office" @update:modelValue="updateHandler('office', $event)"
                    maxlength="10" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Подъезд" :modelValue="props.modelValue.entrance"
                    @update:modelValue="updateHandler('entrance', $event)" maxlength="10" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Код" :modelValue="props.modelValue.code"
                    @update:modelValue="updateHandler('code', $event)" maxlength="10" />
            </div>
            <div class="form-row">
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Телефон" :modelValue="props.modelValue.phone"
                    mask="+7 (000) 000-00-00" @update:modelValue="updateHandler('phone', $event)" maxlength="18"
                    :errors="validator.phone?.$errors || []" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Время работы"
                    :modelValue="props.modelValue.working_hours"
                    @update:modelValue="updateHandler('working_hours', $event)" maxlength="10" />
                <UIInput class="mb-0 col-md-2 col-xl-2" sm label="Обед" :modelValue="props.modelValue.lunch_hours"
                    @update:modelValue="updateHandler('lunch_hours', $event)" maxlength="10" />
                <UISelect class="mb-0 col-md-2 col-xl-2" sm label="Разнос" :modelValue="props.modelValue.spacing"
                    @update:modelValue="updateHandler('spacing', $event ? Boolean($event) : '')"
                    :options="spacingVariants" />
            </div>
            <div class="form-row">
                <UITextarea class="mb-0 col-md-4 col-xl-4" sm label="Комментарий к адресу"
                    :modelValue="props.modelValue.description" @update:modelValue="updateHandler('description', $event)"
                    maxlength="300" />
                <UITextarea class="mb-0 col-md-4 col-xl-4" sm label="Комментарий для логиста"
                    :modelValue="props.modelValue.logist_comment"
                    @update:modelValue="updateHandler('logist_comment', $event)" maxlength="300" />
                <UITextarea class="mb-0 col-md-4 col-xl-4" sm label="Комментарий для менеджера"
                    :modelValue="props.modelValue.manager_comment"
                    @update:modelValue="updateHandler('manager_comment', $event)" maxlength="300" />
            </div>
        </div>
    </div>
</template>
