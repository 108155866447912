<template>
    <div class="form-check" :class="props.class">
        <input type="checkbox" v-uid ref="UICheckbox" class="form-check-input" :checked="!!modelValue"
            @input="emits('update:modelValue', !props.modelValue)" />
            <label :for="UICheckbox?.id" class="form-check-label">{{ props.label }}</label>
    </div>
</template>

<script setup>
import { defineProps, ref, defineEmits } from 'vue';

const props = defineProps({
    modelValue: { type: [Boolean, null], required: true },
    label: { type: String, required: false, default: () => '' },
    class: { type: [String, null], required: false, default: () => null}
})

const emits = defineEmits(['update:modelValue'])
const UICheckbox = ref(null)
</script>

<style scoped>
.ui-textarea {
    position: relative;
}
.ui-textarea__error {
    position: absolute;
    margin: 0px;
    bottom: -20px;
    font-size: 14px;
    color: red; 
}
</style>