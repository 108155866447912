<script setup>
import { defineProps, computed, ref, defineEmits } from "vue"
import { getFormattedPhoneNumber, addToClipboard } from '@/lib/utils'
import { getColorByStatusId } from '@/lib/auxiliary/order.js'
import UISwitcher from "../UI/UISwitcher.vue"
import moment from "moment"
const props = defineProps({
    orders: { type: Array, required: true, default: () => [] },
    ordersInfo: { type: Object, required: true, default: () => ({}) }
})
const emits = defineEmits(['update-order'])

const lastProductInfoIndex = computed(() => {
    return Object.keys(props.ordersInfo.product_info.products).length - 1
})

const orderIdsWithOpenedPhone = ref([])
const showPhone = (orderId) => {
    orderIdsWithOpenedPhone.value.push(orderId)
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-info-circle mr-2"></i> Заказы</div>
        <div class="card-footer text-muted p-1">
            {{ `Заказов: ${props.ordersInfo?.orders_count ?? 0} Клиентов: ${props.ordersInfo?.clients_count ?? 0}. Бутылей:
            ${props.ordersInfo?.product_info?.total_bottles ?? 0}`}}
            <span v-if="Object.keys(props.ordersInfo?.product_info?.products || {})?.length">(
                <span v-for="(productValue, productName, index) in props.ordersInfo?.product_info?.products || []"
                    :key="productName">
                    {{ `${productName}: ${productValue}` }}<span v-show="lastProductInfoIndex !== index">, </span>
                </span>
                ). </span>
            {{ `Оборудование: покупают ${props.ordersInfo.product_info?.equipment_count ?? 0}, берут в аренду:
            ${props.ordersInfo.product_info?.pledge_count ?? 0}. Итого: ${props.ordersInfo.product_info?.total_amount ??
            0} руб.`}}
        </div>
        <div class="card-body p-0">
            <div class="row-order mb-3 border border-secondaary  p-2" v-for="(order) in props.orders" :key="order.id">
                <div class="row">
                    <div class="col-6">
                        <div class="alert alert-danger p-1 mb-1" v-if="order.customer.duty !== '0,00'">
                            Задолженность <b>{{ order.customer.duty + ' ₽' }}</b>
                        </div>
                        <p v-if="order.customer.companyName" class="mt-0"><b>{{ order.customer.companyName }}</b></p>
                        <p :class="{'mt-0': !order.customer.companyName}">{{ order.customer.fullName }}</p>
                        <p>Тел: <u v-if="!orderIdsWithOpenedPhone.includes(order.id)" @click="showPhone(order.id)"><b
                                    style="cursor: pointer">показать телефон</b></u>
                            <u v-else>
                                <b v-for="phone in order.customer.phones" :key="phone"
                                    @click="addToClipboard(phone, 'Телефон скопирован!')" style="cursor: pointer">{{
                                    getFormattedPhoneNumber(phone) }}</b>
                            </u>
                        </p>
                        <p>{{ order.address?.addressString }}</p>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-12">
                                <table class="table table-small mb-0">
                                    <thead>
                                        <tr>
                                            <th class="col-6">название</th>
                                            <th class="col-auto">&nbsp;</th>
                                            <th class="col-auto">кол</th>
                                            <th class="col-auto">цена за ед</th>
                                            <th class="col-auto">оплата</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="orderItem in order.items" :key="orderItem.id">
                                            <td>{{ orderItem.product.name }}</td>
                                            <td>{{ orderItem.productType.name }}</td>
                                            <td>{{ orderItem.quantity }}</td>
                                            <td>{{ orderItem.currentCost + ' ₽' }}</td>
                                            <td>{{ orderItem.paymentType.publicName }}</td>
                                        </tr>
                                        <tr>
                                            <td class="pb-0"></td>
                                            <td class="pb-0"></td>
                                            <td class="pb-0"></td>
                                            <td class="pb-0"><b>{{ order.total_order_amount + ' ₽' }}</b></td>
                                            <td class="pb-0"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 pl-3">
                                <div class="form-group mb-2 col-md-auto col-xl-auto form-check-inline mr-0 mb-0 align-self-end">
                                    <UISwitcher class="mr-2" label="Нужен чек" :modelValue="order.need_check"
                                        @update:modelValue="emits('update-order', { orderId: order.id, fieldName: 'need_check', newValue: $event })" />
                                    |
                                    <UISwitcher class="mr-2 ml-2" label="Заказ проверен" :modelValue="order.is_checked_by_manager"
                                        @update:modelValue="emits('update-order', { orderId: order.id, fieldName: 'is_checked_by_manager', newValue: $event })" />
                                    |
                                    <UISwitcher class="mr-2 ml-2" label="Надо позвонить" :modelValue="order.need_call"
                                        @update:modelValue="emits('update-order', { orderId: order.id, fieldName: 'need_call', newValue: $event })" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <small>
                            {{order.order_comment}}<span v-show="order.order_comment"> | </span>№: <b>{{order.order_number}}</b> | ID: <b>{{ order.id }}</b>
                        </small>
                    </div>
                    <div class="col-auto alert p-1 m-1" :style="`background-color: ${getColorByStatusId(order.status_id.id)}; color: #fff; height: min-content;`">
                        {{ order.status_id.name }}
                    </div>
                    <div class="col-auto">
                        {{ moment(order.delivery_date).format('DD.MM.YYYY') }} {{order.delivery_time?.name ?? 'Нет информации'}}<br />
                        {{ `${order.delivery_time.start.substr(0, 5)} - ${order.delivery_time.end.substr(0, 5)}` }}
                    </div>
                    <div class="col-auto">
                    </div>
                    <div class="col-auto">
                        {{ order.delivery_request?.warehouse?.name ?? '' }}<br />
                        Источник: {{ order.order_source?.name ?? '' }}
                    </div>
                    <div class="col-auto">
                        <template v-if="order.delivery_request?.staffList?.driver?.id">Водитель: {{ order.delivery_request?.staffList?.driver?.firstName ?? '' + ' ' + order.delivery_request?.staffList?.driver?.lastName ?? '' }} 
                        (<span v-for="(phone, idx) in order.delivery_request?.staffList?.driver?.phones" :key="phone" @click="addToClipboard(phone, 'Телефон скопирован!')">
                            <b style="cursor: pointer">{{ getFormattedPhoneNumber(phone) }}</b><span v-show="idx !== order.delivery_request.staffList.driver.phones.length - 1">, </span>
                        </span>)<br />
                        </template>
                        <template v-if="order.delivery_request?.staffList?.forfarders?.length">
                            Экспедитор: 
                            <span v-for="forfarder in order.delivery_request?.staffList?.forfarders" :key="forfarder.id">
                                {{ forfarder.firstName ?? '' + ' ' + forfarder.lastName ?? '' }}
                                (<span v-for="(phone, idx) in forfarder.phones" :key="phone" @click="addToClipboard(phone, 'Телефон скопирован!')">
                                    <b style="cursor: pointer" >{{ getFormattedPhoneNumber(phone) }}</b><span v-show="idx !== forfarder.phones.length - 1">, </span>
                                </span>)
                            </span>
                        </template>
                    </div>
                    <router-link class="ml-auto mr-2 mt-auto text-muted" :to="{path: '/order', query: { orderId: order.id }}"><i class="fa fa-pen mr-1 font-small-1"></i>Редактировать</router-link>
                </div>
            </div>
        </div>
    </div>
</template>