<template>
  <footer class="main-footer">
    <strong>HW © {{ currentYear }}</strong>
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 0.1.1
    </div>
  </footer>
</template>

<script setup>
import moment from 'moment';

const currentYear = moment(new Date()).format('YYYY')
</script>
