<script setup>
import { defineProps, defineEmits, computed, ref, nextTick } from 'vue'
import UISelect from '../UI/UISelect.vue';
import { useParamsStore } from "@/store/params";
import Datapicker from "vue3-datepicker";
import api from "@/common/api";
import { showErrors } from "@/common/showErrors";
import UIInput from '../UI/UIInput.vue';
import UISwitcher from '../UI/UISwitcher.vue';
import ru from 'date-fns/locale/ru';
import debounce from 'lodash/debounce';

const props = defineProps({
    modelValue: {
        type: Object,
        required: true,
        default: () => { }
    }
})
const emits = defineEmits(['update:modelValue', 'search-orders'])
const inputHandler = (fieldName, newValue) => {
    const modelValueCopy = structuredClone(props.modelValue)
    modelValueCopy[fieldName] = newValue
    emits('update:modelValue', modelValueCopy)
    if (fieldName === 'water_company_id') {
        nextTick(() => {
            fetchFiltersOptionsDebounce()
        })
    }
}
const companyCities = computed(() => useParamsStore().cities)

const orderStatuses = ref([])
const fetchOrderStatuses = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/order-statuses`)
        orderStatuses.value = data.data
    } catch (error) {
        showErrors(error)
    }
}

const sortParams = ref([
    { title: 'по дате', value: 'date,desc' },
    { title: 'по типу', value: 'type,desc' },
    { title: 'по имени', value: 'name,desc' },
    { title: 'по дате создания', value: 'created_at,desc' },
    { title: 'по телефону', value: 'phone,desc' }
])

const searchBy = ref('all')
const searchByValue = ref('')
const searchByOptions = ref([
    { title: 'Все', value: 'all' },
    { title: 'Фамилия', value: 'last_name' },
    { title: 'Имя', value: 'first_name' },
    { title: 'ФИО', value: 'full_name' },
    { title: 'Компания', value: 'company' },
    { title: 'Телефон', value: 'phone' },
    { title: 'Адрес', value: 'address' },
    { title: 'Договор', value: 'contract_number' },
    { title: '№ заказа', value: 'order_number' },
])
const updateSearchBy = (event) => {
    searchBy.value = event
    searchByValue.value = ''
    inputHandler('search', `${searchBy.value}, ${searchByValue.value}`)
}
const updateSearchByValue = (event) => {
    searchByValue.value = event
    inputHandler('search', `${searchBy.value}, ${searchByValue.value}`)
}


const deliveryTimesChangeHandler = (timeId) => {
    let deliveryTimesCopy = structuredClone(props.modelValue.delivery_times)
    if (deliveryTimesCopy.includes(timeId)) {
        deliveryTimesCopy = deliveryTimesCopy.filter(id => id !== timeId)
    } else {
        deliveryTimesCopy.push(timeId)
    }
    inputHandler('delivery_times', deliveryTimesCopy)
}
const deliveryTimes = ref([])
const fetchDeliveryTimes = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/delivery-times`, { params: { water_company_id: props.modelValue.water_company_id } })
        deliveryTimes.value = data.data
    } catch (error) {
        showErrors(error)
    }
}


const warehouses = ref([])
const fetchWarehouses = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/warehouses`, { water_company_id: props.modelValue.water_company_id })
        warehouses.value = data.data
    } catch (error) {
        showErrors(error)
    }
}

const managers = ref([])
const fetchManagers = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/users`, { water_company_id: props.modelValue.water_company_id })
        managers.value = data.data
    } catch (error) {
        showErrors(error)
    }
}


const staff = ref([])
const fetchStaff = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/staff`, { water_company_id: props.modelValue.water_company_id })
        staff.value = data.data
    } catch (error) {
        showErrors(error)
    }
}


const paymentTypes = ref([])
const fetchPaymentTypes = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/payment-types`, { params: { water_company_id: props.modelValue.water_company_id } })
        paymentTypes.value = data.data
    } catch (error) {
        showErrors(error)
    }
}

const orderSources = ref([])
const fetchOrderSources = async () => {
    try {
        const { data } = await api.get(`${process.env.VUE_APP_GATE_URL}/crm3core/api/v1/filter-options/order-sources`, { params: { water_company_id: props.modelValue.water_company_id } })
        orderSources.value = data.data
    } catch (error) {
        showErrors(error)
    }
}

const fetchFiltersOptions = () => {
    fetchOrderSources()
    fetchPaymentTypes()
    fetchStaff()
    fetchManagers()
    fetchWarehouses()
    fetchDeliveryTimes()
    fetchOrderStatuses()
}
const fetchFiltersOptionsDebounce = debounce(fetchFiltersOptions, 300)

const inputDateHandler = (field, event) => {
    if (event.target.value === '') {
        inputHandler(field, null)
    }
}

const edoOptions = ref([
    { value: null, title: 'Эдо - неважно' },
    { value: true, title: 'Эдо - да' },
    { value: false, title: 'Эдо - нет' },
])

const wandOptions = ref([
    { value: null, title: 'WAND - неважно' },
    { value: true, title: 'WAND - да' },
    { value: false, title: 'WAND - нет' },
])

const checkedManagerOptions = ref([
    { value: null, title: 'Проверен - неважно' },
    { value: true, title: 'Проверен - да' },
    { value: false, title: 'Проверен - нет' },
])

fetchFiltersOptionsDebounce()
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-info-circle mr-2"></i>Основная информация
        </div>
        <div class="card-body p-2">
            <div class="form-row">
                <UISelect label="Город" :modelValue="props.modelValue.water_company_id" class="col-md-1 col-xl-1"
                    @update:modelValue="inputHandler('water_company_id', $event)" :options="companyCities"
                    :mapOptions="(city) => ({ title: city.short_name, value: city.id })" />
                <div class="form-group mb-0 col-md-3 col-xl-3">
                    <label for="" class="col-form-label pb-1">Период</label>
                    <div class="input-group mb-0">
                        <Datapicker class="form-control form-control-sm" :modelValue="modelValue.date_from"
                            @update:modelValue="inputHandler('date_from', $event)" :locale="ru"
                            input-format='dd.MM.yyyy' format="dd.MM.yyyy" typeable
                            @input="inputDateHandler('date_from', $event)" />
                        <div class="input-group-prepend">
                            <span class="input-group-text form-control-sm">-</span>
                        </div>
                        <Datapicker class="form-control form-control-sm" :modelValue="modelValue.date_to"
                            @update:modelValue="inputHandler('date_to', $event)" :locale="ru" input-format='dd.MM.yyyy'
                            typeable @input="inputDateHandler('date_to', $event)" />
                    </div>
                </div>
                <UISelect label="Статус" class="mb-0 col-md-2 col-xl-2" :modelValue="modelValue.status_id"
                    @update:modelValue="inputHandler('status_id', $event)" :options="orderStatuses"
                    :mapOptions="(option) => ({ title: option.name, value: option.id })" :search="false" clearable />
                <UISelect label="Сортировка" class="mb-0 col-md-2 col-xl-2" :modelValue="modelValue.sort"
                    @update:modelValue="inputHandler('sort', $event)" :options="sortParams" :search="false" />
                <div class="form-group mb-0 col-md-4 col-xl-4">
                    <label class="col-form-label pb-1">Поиск</label>
                    <div class="input-group mb-0">
                        <UISelect class="mb-0 col-3 p-0" inputClass="rounded-end-none" :modelValue="searchBy"
                            @update:modelValue="updateSearchBy" :options="searchByOptions" :search="false" />
                        <UIInput class="flex-grow-1 form-control-sm p-0 mb-0" inputClass="rounded-start-none"
                            :modelValue="searchByValue" @update:modelValue="updateSearchByValue" />
                    </div>
                </div>
            </div>

            <div class="form-row">
                <br />
                <div class="form-group mb-2 form-check-inline mr-0">
                    <UISwitcher v-for="deliveryTime in deliveryTimes" :key="deliveryTime.id" :label="deliveryTime.name"
                        :modelValue="props.modelValue.delivery_times.includes(deliveryTime.id)"
                        @update:modelValue="deliveryTimesChangeHandler(deliveryTime.id)" />
                </div>
                <div class="form-group mb-2 col-md-auto col-xl-auto form-check-inline mr-0 mb-0 align-self-end ml-auto">
                    <UISwitcher class="ml-2 mr-2" :modelValue="props.modelValue.no_coordinates"
                        @update:modelValue="inputHandler('no_coordinates', $event)" label="Нет координат" />
                    |
                    <UISwitcher class="ml-2 mr-2" :modelValue="props.modelValue.no_check"
                        @update:modelValue="inputHandler('no_check', $event)" label="Нет чека" />
                </div>
            </div>

            <div class="form-row">
                <UISelect :modelValue="props.modelValue.warehouse_id" clearable :options="warehouses" :search="false"
                    @update:modelValue="inputHandler('warehouse_id', $event)" clearOptionTitle="Все склады"
                    :mapOptions="(warehouse) => ({ value: warehouse.id, title: warehouse.name })" />
                <UISelect :modelValue="props.modelValue.manager_id" clearable :options="managers"
                    @update:modelValue="inputHandler('manager_id', $event)" clearOptionTitle="Все менеджеры"
                    :mapOptions="(manager) => ({ value: manager.id, title: manager.name })" />
                <UISelect :modelValue="props.modelValue.driver_id" clearable :options="staff"
                    @update:modelValue="inputHandler('driver_id', $event)" clearOptionTitle="Все водители"
                    :mapOptions="(driver) => ({ value: driver.id, title: driver.last_name + ' ' + driver.first_name })" />
                <UISelect :modelValue="props.modelValue.payment_type_id" clearable :options="paymentTypes"
                    @update:modelValue="inputHandler('payment_type_id', $event)" clearOptionTitle="Любая оплата"
                    :mapOptions="(paymentType) => ({ value: paymentType.id, title: paymentType.name })" />
                <UISelect :modelValue="props.modelValue.source_id" clearable :options="orderSources"
                    @update:modelValue="inputHandler('source_id', $event)" clearOptionTitle="Все источники"
                    :mapOptions="(orderSource) => ({ value: orderSource.id, title: orderSource.name })" />
                <UISelect :modelValue="props.modelValue.edo" @update:modelValue="inputHandler('edo', $event)"
                    :options="edoOptions" />
            </div>

            <div class="form-row">
                <UISelect :modelValue="props.modelValue.wand" @update:modelValue="inputHandler('wand', $event)"
                    :options="wandOptions" />
                <UISelect :modelValue="props.modelValue.is_checked_by_manager" @update:modelValue="inputHandler('is_checked_by_manager', $event)"
                    :options="checkedManagerOptions" />

                <div class="form-group mb-0 col-md-2 col-xl-2"></div>
                <div class="form-group mb-0 col-md-2 col-xl-2"></div>
                <div class="form-group mb-0 col-md-2 col-xl-2"></div>
                <div class="form-group mb-0 col-md-2 col-xl-2">
                    <button type="submit"
                        class="form-control form-control-sm btn btn-sm btn-primary waves-effect waves-light"
                        @click="emits('search-orders')">Фильтровать</button>
                </div>
            </div>
        </div>
    </div>
</template>