<script setup>
import {ref} from "vue";
import api from "@/common/api";
import {useRoute} from "vue-router";
import {showErrors} from "@/common/showErrors";
import StaffForManagerMenu from "@/components/Staff/StaffForManagerMenu.vue";
import {useToast} from "vue-toastification";
import {removeEmptyFromObject} from "@/lib/utils";
import {getStaffById} from "@/lib/api/staff";

const toast = useToast();
const route = useRoute();
const staff = ref(null);
const getStaff = async () => {
  try {
    staff.value = await getStaffById(route.params.staffId)
  } catch (error) {
    showErrors(error)
  }
}

const notificationData = ref({
  staffId: route.params.staffId,
  title: null,
  description: null,
});

const showForm = ref(false);

const openForm = () => {
  showForm.value = true;
}

const sendNotification = async () => {
  const preparedNotificationData = removeEmptyFromObject({
    ...notificationData.value,
    title: notificationData.value.title?.trim() || null,
    description: notificationData.value.description?.trim() || null,
  });
  try {
    await api.post(process.env.VUE_APP_GATE_URL + '/salary/api/v1/salary/driver-balance/notification/',
        preparedNotificationData
    )
    toast.success('Уведомление отправлено');
    showForm.value = false;
  } catch (error) {
    toast.error(error.response?.data?.message?.body || 'Ошибка при отправке сообщения');
  }
}

getStaff()

</script>

<template>
  <div class="content-wrapper">
    <!-- header -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-9">
            <H1>Отправка уведомления о задолженности для водителя {{ staff?.firstName }} {{ staff?.lastName }} {{ staff?.patronymic }}</H1>
          </div>
          <div class="col-sm-3">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'MainPage'}"><i class="nav-icon fas fa-home"></i></router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- header off -->
    <!-- content on -->
    <section class="content">
      <div class="container-fluid">
        <StaffForManagerMenu></StaffForManagerMenu>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="d-flex flex-column gap-1">
            <button class="btn btn-primary" @click="sendNotification()">Отправить уведомление о задолженности</button>
            <button class="btn btn-info" @click="openForm">Открыть форму для редактирования уведомления</button>
          </div>
        </div>

        <div v-if="showForm" class="row mt-3">
          <div class="col-md-6">
            <form @submit.prevent="sendNotification">
              <div class="form-group">
                <label for="title">Заголовок</label>
                <input type="text" class="form-control" id="title" v-model="notificationData.title">
              </div>
              <div class="form-group">
                <label for="description">Описание</label>
                <textarea class="form-control" id="description" v-model="notificationData.description"></textarea>
              </div>
              <button type="submit" class="btn btn-primary">Отправить отредактированное уведомление</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- content off -->
</template>

<style scoped>
  .gap-1 {
    gap: 1rem;
  }
</style>