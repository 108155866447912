<script setup>
import {defineProps, ref, onBeforeMount, defineEmits, computed} from 'vue';
import axios from "axios";
import {showErrors} from "@/common/showErrors";
import {useToast} from 'vue-toastification';

const toast = useToast();

const props = defineProps({
  item: {
    type: Object,
    required: true,
    products: {
      type: Array,
      required: true,
      items: {
        type: Object,
      }
    }
  },
  statuses: {
    required: true,
    data: {
      type: Array,
      required: true
    }
  },
  initiators: {
    required: true,
    data: {
      type: Object,
      required: true
    }
  }
});
const emits = defineEmits(['update-summary']);

const item = ref(props.item);
const delivered = computed(() => props.item.status?.id === 6);
const notDelivered = computed(() => props.item.status?.id === 7);

const submit = () => {
  axios.patch(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/delivery-request/update/order/' + item.value.id, {
    countBottles: item.value.countBottles,
    products: item.value.products
  }, {
    headers: {'Authorization': 'Bearer ' + localStorage.access_token}
  }).then(({data}) => {
    if (data) {
      toast.success("Заказ обновлен");
      emits('update-summary');
    }
  }).catch(({response}) => {
    showErrors(response)
  });
}

const paymentType = (id) => {
  switch (id) {
    case 1:
      return "б/нал"
    case 2:
      return "б/опл"
    case 3:
      return "бартер"
    case 4:
      return "нал."
    case 5:
      return "нал. с док."
    case 6:
      return "картой"
    case 7:
      return "б/нал"
    case 8:
      return "б/опл"
    case 9:
      return "бартер"
    case 10:
      return "нал"
    case 11:
      return "нал. с док."
    case 12:
      return "картой"
    case 13:
      return "моб. терм."
    default:
      return ""
  }
}

const cashPaymentTypes = [4, 5, 10, 11];

const filterStatuses = (paymentTypeId, statuses) => {
  const isCashPayment = cashPaymentTypes.includes(paymentTypeId);
  return statuses.filter(status =>
      status.id >= 1 && status.id <= 7 && (isCashPayment ? ![6, 7].includes(status.id) : [6, 7].includes(status.id))
  );
};

const getReasons = (initiatorId) => {
  const productInitiator = props.initiators.find(initiator => initiator.id === initiatorId);
  return productInitiator ? productInitiator.reasons : [];
};

onBeforeMount(() => {
  item.value.products.forEach(product => {
    product.status = product.status === null
        ? (cashPaymentTypes.includes(product.paymentTypeId) ? 4 : 7)
        : product.status.id;
    product.initiator = product.initiator === null ? null : product.initiator.id;
  });
});

</script>

<style scoped>
.card.delivered {
  background-color: #e3efe3;
}

.card.not-delivered {
  background-color: #f8eaea;
}
</style>

<template>
  <div class="card" :class="{'delivered': delivered, 'not-delivered': notDelivered}">
    <div class="card-body">
      <div class="row">
        <div class="col-8 text-sm">
          <b>№ {{ item.orderNumber }}</b> / {{ item.address ? item.address.phoneString : null }}<br/>
          <b>{{ item.customer.companyName }}</b> {{ item.customer.fullName }}<br/>
          {{ item.address ? item.address.addressString : null }}
        </div>
        <div class="col-4 text-right text-sm">
          <div class="form-row">
            <div class="form-group col-3">
              <label for="brought" class="col-form-label" title="Бутылей привезли">Привезли</label>
              <input type="number" class="form-control form-control-sm" v-model="item.countBottles.brought"
                     :disabled="true">
            </div>
            <div class="form-group col-3">
              <label for="returned" class="col-form-label" title="Бутылей клиент вернул">Вернут</label>
              <input type="number" class="form-control form-control-sm" v-model="item.countBottles.returned"
                     :disabled="true">
            </div>
            <div class="form-group col-3">
              <label for="took" class="col-form-label" title="Бутылей водитель забрал">Забрал</label>
              <input type="number" class="form-control form-control-sm" v-model="item.countBottles.took">
            </div>
            <div class="form-group col-3 align-bottom">
              <label for="wcid" class="col-form-label col-12">&nbsp;</label>
              <button type="submit" class="btn btn-sm btn-success waves-effect waves-light" @click="submit">OK</button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <table class="table table-striped table-sm">
          <thead class="text-sm">
          <tr>
            <th>#</th>
            <th>Товар</th>
            <th>Оплата</th>
            <th></th>
            <th>Код</th>
            <th>Статус</th>
            <th>Долг</th>
            <th>Кто виноват</th>
            <th>Причина</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in item.products" :key="index">
            <td>{{ index + 1 }}.</td>
            <td class="text-sm">{{ product.name }}</td>
            <td class="text-sm">{{ paymentType(product.paymentTypeId) }}</td>
            <td>{{ product.quantity }}&nbsp;шт</td>
            <td>
              <input type="text" class="form-control form-control-sm" v-model="product.code">
            </td>
            <td>
              <select size="1" class="form-control form-control-sm" v-model="product.status">
                <option v-for="(item) in filterStatuses(product.paymentTypeId, statuses)" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </td>
            <td>
              <input type="number" class="form-control form-control-sm" v-model="product.duty">
            </td>
            <td>
              <select size="1" class="form-control form-control-sm" v-model="product.initiator">
                <option :value="null" :selected=true>---</option>
                <option v-for="(item) in initiators" :key="item.id" :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </td>
            <td>
              <select size="1" class="form-control form-control-sm" v-model="product.cause">
                <option :value="null" selected>---</option>
                <option v-for="reason in getReasons(product.initiator)" :key="reason.id" :value="reason.name">
                  {{ reason.name }}
                </option>
              </select>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>