<script setup>
import { defineProps, defineEmits } from 'vue'
import { getFormattedPhoneNumber } from '@/lib/utils'
const props = defineProps({
    clients: { type: Array, required: false, default: () => [] },
    showButtons: { type: Boolean, required: false, default: () => false }
})

const emits = defineEmits(['search', 'add'])

const getFullNameByClient = (client) => {
    return `${client.last_name ?? ''} ${client.first_name ?? ''} ${client.patronymic ?? ''}`
}
</script>

<template>
    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1">
            <h3 class="card-title"><i class="fas fa-info-circle mr-2"></i>{{ clients.length ? `Клиенты
                (${props.clients.length})` : 'Клиентов не найдено, уточните поиск' }}</h3>
        </div>
        <div class="card-body" v-if="props.clients.length || showButtons">
            <div class="form-row pb-2" v-if="showButtons">
                <div class="form-group mb-0 mt-2 col-md-auto col-xl-auto">
                    <button type="submit" class="btn btn-primary waves-effect waves-light mr-2"
                        @click="emits('search')">Найти совпадения</button>
                    <button type="submit" class="btn btn-primary waves-effect waves-light"
                        @click="emits('add-client')">Добавить нового
                        клиента</button>
                </div>
            </div>
            <table class="table" v-if="props.clients.length">
                <thead>
                    <tr>
                        <th class="col-auto">#</th>
                        <th class="col-4">Компания, ФИО, Телефон</th>
                        <th class="col-auto">Адрес</th>
                        <th class="col-2">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(client, idx) in props.clients" :key="client.id">
                        <td class="col-auto">{{ idx + 1 }}.</td>
                        <td class="col-4">
                            {{ getFormattedPhoneNumber(client.phone) }}<br
                                v-show="client.phone" />
                            {{ client.type.shortName }}<br v-show="client.type.shortName" />
                            <template v-if="client.company">
                                <b>{{ client.company }}</b><br />
                            </template>
                            {{ getFullNameByClient(client) }}
                        </td>
                        <td class="col-auto">
                            <div class="row" v-for="(address, idx) in client.addresses" :key="address.id">
                                <a href="#" v-show="idx === 0 && address.address_source_string">
                                    <i class="fas fa-plus mr-2 address__icon"></i><b>{{ address.address_source_string
                                        }}</b>
                                </a>
                                <a href="#" v-show="idx > 0 && address.address_source_string">
                                    <i class="fas fa-plus mr-2 address__icon"></i>{{ address.address_source_string }}
                                </a>
                            </div>
                        </td>
                        <td class="col-2">
                            <div class="row">
                                <div class="col-md-12">
                                    <a href="#">
                                        <i class="fas fa-info-circle mr-2"></i>Статистика
                                    </a>
                                </div>
                                <div class="col-md-12">
                                    <router-link :to="`/customers/${client.id}`">
                                        <i class="fas fa-id-card-alt mr-2"></i>Просмотр
                                    </router-link>
                                </div>
                                <div class="col-md-12">
                                    <router-link :to="{path: '/order', query: {customerId: client.id}}">
                                        <i class="fas fa-plus mr-2"></i>Добавить заказ
                                    </router-link>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped lang="scss">
.address__icon {
    color: #17a2b8;
}
</style>