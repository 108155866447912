<script setup>
import { defineProps, defineEmits, ref, computed } from 'vue'
import axios from "axios";
import debounce from 'lodash/debounce';
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
    modelValue: { type: String, required: true },
    label: { type: String, required: false, default: () => '' },
    errors: { type: Array, required: false, default: () => [] },
    readonly: { type: Boolean, required:  false, default: () => false }
})
const emits = defineEmits(['update:modelValue'])

const UIInput = ref(null)


const udpateQueryHandler = (event) => {
    const newQuery = event.target.value
    emits('update:modelValue', { desc: '', name: newQuery })
    updateAddressSugestionsDebounce()
}

const addressSugestions = ref([])
const updateAddressSugestionsAsync = async () => {
    const lengthForSearch = 5
    if (props.modelValue.length >= lengthForSearch) {
        try {
            const { data } = await axios.get('https://hvalwaters.ru/suggest-geo/', {
                params: {
                    cut_country_from_fullpath: 1,
                    from: 'suggest',
                    lang: 'ru_RU',
                    bases: 'house',
                    in: '2,10174',
                    search_type: 'tp',
                    types: 'houses',
                    part: props.modelValue,
                    results: 8,
                    v: 7,
                    callback: ''
                }
            })
            addressSugestions.value = data.results

        } catch (error) {
            console.error(error)
        }
    } else {
        addressSugestions.value = []
    }
}
const updateAddressSugestionsDebounce = debounce(updateAddressSugestionsAsync, 300)

const isActiveElement = ref(false)
const autocompleteElement = ref(null)
onClickOutside(autocompleteElement, () => isActiveElement.value = false)
const showSuggestions = computed(() => {
    return isActiveElement.value && addressSugestions.value.length && !props.readonly
})

const inputHandler = (suggest) => {
    emits('update:modelValue', suggest)
    isActiveElement.value = false
    autocompleteElement.value.blur()
}
</script>

<template>
    <div ref="autocompleteElement" class="autocomplete-address form-group col-md-2 col-xl-2"
        @blur="isActiveElement = false">
        <label :for="UIInput?.id" class="col-form-label" :class="{ 'pb-1': props.sm }">{{ props.label }}</label>
        <input v-uid ref="UIInput" type="text" class="form-control form-control-sm" :value="props.modelValue" :readonly="props.readonly"
            @input="udpateQueryHandler" @focus="isActiveElement = true"
            :class="errors.length ? 'has-error ' : ''">
        <div class="autocomplete-address__suggestions-container" v-if="showSuggestions">
            <p v-for="suggest in addressSugestions" :key="suggest.name" class="autocomplete-address__suggestion"
                @click="inputHandler(suggest)">
                {{ suggest.name }}
            </p>
        </div>
        <p v-if="errors.length" class="autocomplete-address__error">{{ errors[0].$message }}</p>
    </div>
</template>

<style lang="scss" scoped>
.autocomplete-address {
    position: relative;
    .has-error {
        border-color: red;
    }
    .autocomplete-address__error {
        position: absolute;
        margin: 0px;
        bottom: -8px;
        font-size: 12px;
        color: red;
    }

    .autocomplete-address__suggestions-container {
        position: absolute;
        margin-left: 5px;
        top: 100%;
        left: 0px;
        width: calc(100% - 10px);
        z-index: 10;
        padding: 8px 0px;
        background-color: white;
        border: 1px gray solid;
        border-radius: 0 0 8px 8px;
        overflow: hidden;


        .autocomplete-address__suggestion {
            margin: 0px;
            padding: 4px 4px;
            cursor: pointer;
        }

        .autocomplete-address__suggestion:hover {
            background-color: gray;
        }
    }
}
</style>