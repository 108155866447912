export default class BaseClient {
    water_company_id = 1
    phone = null
    type = 2
    company = null
    company_inn = null
    last_name = null
    first_name = null
    patronymic = null
    contract_number = null
    payment_type = null
    fixed_water_price = null
    source = null
    choice = null
    addresses = []
    id = null
    duty = '0,00'
    customerBindings = []

    constructor(client) {
        this.water_company_id = client?.water_company?.id ?? 1
        this.phone = client?.phone ?? null
        this.type = client?.type?.id ?? 2
        this.company = client?.company ?? null
        this.company_inn = client?.company_inn ?? null
        this.last_name = client?.last_name ?? null
        this.first_name = client?.first_name ?? null
        this.patronymic = client?.patronymic ?? null
        this.contract_number = client?.contract_number ?? null
        this.payment_type = client?.payment_type ?? null
        this.fixed_water_price = client?.fixed_water_price ?? null
        this.source = client?.source ?? null
        this.choice = client?.choice ?? null
        this.addresses = client?.addresses ?? []
        this.id = client?.id ?? null
        this.duty = client?.duty ?? '0,00'
        this.customerBindings = client?.customerBindings ?? []
    }
}