<script setup>
import UIInput from "../UI/UIInput.vue";
import UISelect from "../UI/UISelect.vue";
import { defineProps, defineEmits, computed, ref } from "vue"
import { useParamsStore } from "@/store/params";
import api from "@/common/api";
import debounce from "lodash/debounce";

const clientTypes = computed(() => useParamsStore().clientTypes)

const props = defineProps({
    modelValue: { type: Object, required: true },
    errors: { type: Array, required: false, default: () => [] },
    isMainInfo: { type: Boolean, required: false, default: () => false },
    validator: { type: Object, required: false, default: () => ({}) }
})

const emits = defineEmits(['update:modelValue', 'new-error'])

const updateHandler = (field, newValue) => {
    const newModelValue = { ...props.modelValue }
    newModelValue[field] = newValue
    emits('update:modelValue', newModelValue)
}

const companyCities = computed(() => useParamsStore().cities)
const paymentTypes = ref([])
const fetchPaymentTypesAsync = async () => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/payment-types', {
            params: {
                water_company_id: props.modelValue.water_company_id
            }
        })
        paymentTypes.value = data.data
        updateHandler('payment_type', null)
    } catch (error) {
        console.error(error)
    }
}
const fetchPaymentTypesDebounce = debounce(fetchPaymentTypesAsync, 300)
fetchPaymentTypesDebounce()

const customerSource = ref([])
const fetchCustomerSourceAsync = async () => {
    try {
        const { data } = await api.get(process.env.VUE_APP_GATE_URL + '/crm3core/api/v1/filter-options/customer-sources')
        customerSource.value = data.data
    } catch (error) {
        console.error(error)
    }
}
fetchCustomerSourceAsync()
</script>

<template>
    <div class="card" v-if="isMainInfo">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-info-circle mr-2"></i>Основная информация
        </div>
        <div class="card-body p-2">
            <div class="form-row">
                <UISelect label="Город" class="mb-0 col-md-4 col-xl-2" :modelValue="modelValue.water_company_id"
                    :options="companyCities" :mapOptions="(city) => ({ title: city.short_name, value: city.id })" sm
                    @update:modelValue="updateHandler('water_company_id', $event); fetchPaymentTypesDebounce()" />
                <UIInput class="mb-0 col-md-4 col-xl-2" :modelValue="props.modelValue.contract_number" :mask="Number" sm
                    maxlength="20" @update:modelValue="updateHandler('contract_number', $event)" label="Номер договора"
                    :errors="validator.contract_number?.$errors ?? []" />
                <UISelect class="mb-0 col-md-4 col-xl-2" label="Тип оплаты" :modelValue="modelValue.payment_type" sm
                    :options="paymentTypes" :mapOptions="(type) => ({ title: type.name, value: type.id })"
                    @update:modelValue="updateHandler('payment_type', $event)" :errors="validator.payment_type?.$errors ?? []" />
                <UISelect class="mb-0 col-md-4 col-xl-2" label="Откуда узнали о нас" :modelValue="modelValue.source" sm
                    :options="customerSource" :mapOptions="(source) => ({ title: source.name, value: source.id })"
                    @update:modelValue="updateHandler('source', $event)" :errors="validator.source?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-2" :modelValue="modelValue.choice" sm maxlength="20" 
                    @update:modelValue="updateHandler('choice', $event)"
                    label="Почему выбрали нас" :errors="validator.choice?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-2" :modelValue="''" sm maxlength="20" label="Кто пригласил" />
            </div>
            <div class="form-row"></div>
        </div>
    </div>

    <div class="card">
        <div class="card-header alert-info p-2 pt-1 pb-1"><i class="fas fa-user mr-2"></i>Клиент
        </div>
        <div class="card-body p-2">
            <div class="form-row">
                <UISelect class="mb-0 col-md-3 col-xl-3" label="Тип" :modelValue="modelValue.type" sm
                    :options="clientTypes" @update:modelValue="updateHandler('type', +$event)" newType />
                <UIInput class="mb-0 col-md-3 col-xl-3" :modelValue="props.modelValue.phone" sm maxlength="18"
                    @update:modelValue="updateHandler('phone', $event)" label="Телефон" mask="+7 (000) 000-00-00"
                    :errors="validator.phone?.$errors ?? []" />
                <UIInput class="mb-0 col-md-3 col-xl-3" :modelValue="props.modelValue.fixed_water_price" sm
                    maxlength="11" @update:modelValue="updateHandler('fixed_water_price', $event)" label="Фикс. цена" />
            </div>
            <div class="form-row">
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.last_name" sm maxlength="100"
                    @update:modelValue="updateHandler('last_name', $event)" label="Фамилия"
                    :errors="validator.last_name?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.first_name" sm maxlength="100"
                    @update:modelValue="updateHandler('first_name', $event)" label="Имя"
                    :errors="validator.first_name?.$errors ?? []" />
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.patronymic" sm maxlength="100"
                    @update:modelValue="updateHandler('patronymic', $event)" label="Отчество" />
            </div>
            <!-- Если Юр.лицо-->
            <div v-if="modelValue.type === 1" class="form-row">
                <UIInput class="mb-0 col-md-4 col-xl-4" :modelValue="props.modelValue.company" sm maxlength="300"
                    @update:modelValue="updateHandler('company', $event)" label="Компания" :errors="validator.company?.$errors ?? []" />
                <UIInput class="mb-0 col-md-3 col-xl-3" :modelValue="props.modelValue.company_inn" sm maxlength="12"
                    @update:modelValue="updateHandler('company_inn', $event)" label="ИНН" />
                <UIInput class="mb-0 col-md-5 col-xl-5" :modelValue="props.modelValue.company_description || ''" sm
                    maxlength="300" @update:modelValue="updateHandler('company_description', $event)"
                    label="Описание" />
            </div>
        </div>
    </div>
</template>